<template>
  <div class="divBox">
    <div class="view">
      <div>
        <el-tabs :tab-position="'left'" v-model="activeName">
          <el-tab-pane label="文章专题" name="1" v-if="showTab.includes(1)">
            <ArticleCom v-if="activeName == 1" @selectItem="selectItem" />
          </el-tab-pane>
          <el-tab-pane label="商城链接" name="2" v-if="showTab.includes(2)">
            <ShopLink v-if="activeName == 2" @selectItem="selectItem" />
          </el-tab-pane>
          <el-tab-pane label="门店商品" name="3" v-if="showTab.includes(3)">
            <GoodsLink v-if="activeName == 3" @selectItem="selectItem" />
          </el-tab-pane>
          <el-tab-pane label="商城分组" name="4" v-if="showTab.includes(4)">
            <ClassifyLink v-if="activeName == 4" @selectItem="selectItem" />
          </el-tab-pane>
          <el-tab-pane label="门店活动" name="5" v-if="showTab.includes(5)">
            <ActiveLink v-if="activeName == 5" @selectItem="selectItem" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getConfigList,
  homeUpdate,
  configDelete,
  configCopy,
} from "@/api/decorate/decorate";
import { getIndex } from "@/utils/other";
import ArticleCom from "./articleCom";
import ShopLink from "./shopLink";
import GoodsLink from "./goodsLink.vue";
import ClassifyLink from "./classifyLink.vue";
import ActiveLink from "./activeLink.vue";
export default {
  name: "changeLink",
  components: {
    ArticleCom,
    ShopLink,
    GoodsLink,
    ClassifyLink,
    ActiveLink,
  },
  props: {
    showTab: {
      type: Array,
      default: function () {
		  	return [1,2,3,4,5]
		  }
    },
  },
  data() {
    return {
      activeName: "1",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      getConfigList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    selectItem(val) {
      console.log("1231====");
      if (this.activeName == 1) {
        let url = `/pages/home/article/index?id=${val.id}`;
        this.$emit("changeUrl", url);
      }
      if (this.activeName == 2) {
        let url = val.url;
        this.$emit("changeUrl", url);
      }
      if (this.activeName == 3) {
        let url = `/pages/goods/details/index?id=${val.id}`;
        this.$emit("changeUrl", url);
      }
      if (this.activeName == 4) {
        let url = `/pages/goods/screen/index?id=${val.id}`;
        this.$emit("changeUrl", url);
      }
      if (this.activeName == 5) {
        let url = `/pages/home/activity/index?id=${val.id}`;
        this.$emit("changeUrl", url);
      }
    },
    onEdit(id) {
      this.$router.push({
        path: "/pagesCustom/index",
        query: {
          id: id,
        },
      });
    },
    del(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        configDelete(id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    homeUpdateIndex(id) {
      homeUpdate(id)
        .then((res) => {
          this.$message.success(res.msg);
        })
        .catch((res) => {});
    },
    copy(id) {
      configCopy(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getList();
        })
        .catch((res) => {});
    },
    add() {
      this.$router.push({
        path: "/pagesCustom/index",
      });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: space-between;
}
.view {
  width: 100%;
  min-height: 500px;
  height: 100%;
}
.table {
  width: calc(100% - 380px);
  height: 100;
  min-height: 700px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
</style>