<template>
  <div>
    <div class="divBox">
      <div class="table"> 
        <div>
          <el-table
            v-loading="listLoading"
            :data="tableData.data"
            size="small"
            row-key="id"
            ref="refsTable"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
          <el-table-column type="selection" width="55" />
            <el-table-column prop="sort" label="排序" min-width="50" />
            <el-table-column label="分组名称" prop="name" min-width="80">
            </el-table-column>
            <el-table-column label="分组图标" min-width="80">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.icon ? scope.row.icon : moren"
                    :preview-src-list="[
                      scope.row.icon ? scope.row.icon : moren,
                    ]"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="status" label="是否显示" min-width="150">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isShow"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="显示"
                  inactive-text="隐藏"
                  @change="onchangeIsShow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="isPopup"
              label="是否首页弹框显示"
              min-width="150"
            >
              <template slot-scope="scope">
                <el-switch
                  v-if="scope.row.level == 1"
                  v-model="scope.row.isPopup"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="显示"
                  inactive-text="隐藏"
                  @change="onchangeIndexIsShow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="60" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="onEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="footBox">
      <el-button type="primary" size="mini" @click="save">确定</el-button>
    </div>
  </div>
</template>  
<script>
import { goodsClassifyStoreList } from "@/api/store/goodsClassify";
import { getIndex } from "@/utils/other";
export default {
  name: "classifyLink",
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      selectionOne: [], //选中的数据
      tableFrom: {
        name: "",
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    getList() {
      this.listLoading = true;
      goodsClassifyStoreList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data ? res.data : [];
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          // this.$message.error(res.message);
        });
    },
    save() {
      if (this.selectionOne.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.$emit("selectItem", this.selectionOne[0]);
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  min-height: 500px;
}
.view {
  min-height: 500px;
  height: 100%;
}
.table {
  width: 100%;
  height: 100;
  min-height: 600px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
.footBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>