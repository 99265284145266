<template>
  <div class="product-content">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabBox">
      <el-tab-pane label="内容设置" name="content"></el-tab-pane>
      <el-tab-pane label="样式设置" name="styles"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'content'">
      <div class="headTips">最多可添加4张图片，建议宽度750*345px</div>
      <Draggable
        v-model="content.list"
        animation="300"
        draggable=".listBox"
        handle=".dragBox"
      >
        <div class="listBox" v-for="(item, index) in content.list" :key="index">
          <div class="dragBox">
            <img src="@/assets/images/dian.png" />
          </div>

          <div class="imageBox" v-if="item.img">
            <!-- <i
              class="el-icon-error"
              @click.stop="del(index)"
              style="font-size: 22px"
            ></i> -->
            <img :src="item.img" @click="showDio(index)" />
          </div>

          <div class="imageBox" v-else @click="showDio(index)">
            <!-- <i
              class="el-icon-error"
              @click.stop="del(index)"
              style="font-size: 22px"
            ></i> -->
            <i class="el-icon-camera-solid" style="font-size: 30px"></i>
          </div>

          <div class="rightConter">
            <div class="info-item">
              <span class="info-title">标题</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  v-model="item.title"
                  maxlength="6"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="info-item">
              <span class="info-title">简介</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  v-model="item.introduction"
                  maxlength="20"
                  placeholder="请输入简介"
                ></el-input>
              </div>
            </div>
            <div class="info-item" style="margin-top: 10px">
              <span class="info-title">链接</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  disabled
                  v-model="item.value"
                  placeholder="请选择内容"
                >
                  <template slot="append">
                    <el-button
                      slot="append"
                      @click="linkDio(index)"
                      icon="el-icon-arrow-right"
                    ></el-button>
                  </template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
      <!-- <el-button v-if="content.list.length<4" plain style="width: 100%; height: 40px" @click="add"
        >添加板块</el-button
      > -->
    </div>

    <div v-if="activeName == 'styles'"> 
      <p class="titleBox">
        <span>选择样式</span
        >{{ content.styles.showType == "one" ? "单行展示" : "多行展示" }}
      </p>
      <div class="tabBox" style="display: flex;margin-top: 10px;">
        <div
          class="itemTab"
          :style="
            content.styles.showType == 'one' ? 'border:1px solid #4073FA;' : ''
          "
          @click="content.styles.showType = 'one'"
        >
        <img class="iconImg" src="@/assets/icon/icon15.png"/>
        </div>
        <div
          class="itemTab"
          :style="
            content.styles.showType == 'multiLine'
              ? 'border:1px solid #4073FA;'
              : ''
          "
          @click="content.styles.showType = 'multiLine'"
        >
        <img class="iconImg" src="@/assets/icon/icon16.png"/>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">背景颜色</div>

        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGround"
            @change="startChange"
          ></el-color-picker
          ><span
            class="reset"
            @click="startChange('')"
            style="margin-right: 17px"
            >重置</span
          >
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>

    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>
    <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";
import Draggable from "vuedraggable";
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "Product",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
    ChangeMaterial,
    ChangeLink,
  },
  data() {
    return {
      clickIndex: null,
      show: false,
      showLink: false,
      activeName: "content",
      value: "",
      value1: "",
      color1: "",
      indicatorE: {
        circular: "圆形",
        line: "线",
        none: "无",
      },
      indicatorPositionE: {
        left: "居左",
        center: "居中",
        right: "居右",
      },
      content: {
        list: [
          {
            img: "",
            introduction: "",
            title: "",
            value: "",
          },
          {
            img: "",
            introduction: "",
            title: "",
            value: "",
          },
          {
            img: "",
            introduction: "",
            title: "",
            value: "",
          },
          {
            img: "",
            introduction: "",
            title: "",
            value: "",
          },
        ],
        styles: {
          showType:'one',//one单multiLine多
          backGround: "#F3F6F9",
          marginTop: 0,
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    showDio(index) {
      this.clickIndex = index;
      this.show = true;
    },
    linkDio(index) {
      this.clickIndex = index;
      this.showLink = true;
    },
    changwImg(val) {
      this.content.list[this.clickIndex].img = val;
      this.show = false;
    },
    changeUrl(val) {
      this.content.list[this.clickIndex].value = val;
      this.showLink = false;
    },
    startChange(e) {
      if (!e) {
        this.content.styles.backGround = "#F3F6F9";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      this.content.list.splice(index, 1);
    },
    // add() {
    //   let obj = {
    //     img: "",
    //     introduction: "",
    //     title: "",
    //     value: "",
    //   };
    //   this.content.list.push(obj);
    // },
  },
};
</script>

<style lang="scss" scoped>
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
.itemTab {
  width: 67px;
  height: 34px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdee2;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}
.titleBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  span {
    color: #999;
    margin-right: 10px;
  }
}
</style>