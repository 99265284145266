<template>
  <div>
    <div style="min-height: 500px">
      <div class="box">
        <div
          class="card"
          :style="
            actIndex == index
              ? {
                  background: '#333',
                  color: '#fff',
                }
              : ''
          "
          v-for="(item, index) in list"
          :key="index"
          @click="actIndex=index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="footBox">
      <el-button type="primary" @click="save" size="mini">确定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "shopLink",
  data() {
    return {
      actIndex: -1,
      list: [
        {
          title: "商城首页",
          url: "/pages/index/index",
        },
        {
          title: "账户积分",
          url: "/pages/profile/integral/index",
        },
        {
          title: "商品分类",
          url: "/pages/tabbar/classify/index",
        },
        {
          title: "商品搜索页",
          url: "/pages/home/search/index",
        },
        {
          title: "购物车",
          url: "/pages/tabbar/car/index",
        },
        {
          title: "个人中心",
          url: "/pages/tabbar/profile/index",
        },
        {
          title: "用户收藏",
          url: "/pages/profile/collect/index",
        },
        {
          title: "浏览记录",
          url: "/pages/profile/record/index",
        },
        {
          title: "用户订单",
          url: "/pages/profile/order/list/index",
        },
        {
          title: "用户售后单",
          url: "/pages/profile/afterSale/list/index",
        },
        {
          title: "个人资料",
          url: "/pages/profile/info/index",
        },
        {
          title: "专属店铺",
          url: "/pages/profile/store/index",
        },
        {
          title: "关于商城",
          url: "/pages/profile/about/index",
        },
        {
          title: "用户地址",
          url: "/pages/profile/address/list/index",
        },
        {
          title: "意见反馈",
          url: "/pages/profile/feedback/index",
        },
        {
          title: "用户协议",
          url: "/pages/home/agreement/index",
        },
      ],
    };
  },
  created() {},
  methods: {
    save() {

      this.$emit("selectItem", this.list[this.actIndex]);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  min-height: 500px;
}
.view {
  min-height: 500px;
  height: 100%;
}
.table {
  width: 100%;
  height: 100;
  min-height: 600px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
.footBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.card {
  width: auto;
  height: 40px;
  padding: 0 20px;
  background-color: #f4f5f9;
  margin-right: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>