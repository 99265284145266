<template>
  <section>
    <div class="tab-content">
       {{ list[data.type]['tit'] }}
        </div>
        <component 
            :is="list[data.type]['com']" 
            :data="data"
        ></component>
    </section>
</template>

<script>
import Swiper from "./Swiper"
import Search from "./Search"
import Tabs from "./Tabs"
import MakeUpComponent from "./MakeUpComponent"
import GoodsList from "./GoodsList"
import Recommend from "./Recommend"
import Navigation from "./Navigation"
import News from "./News"
import Images from "./Images"
import Icon from "./Icon"
import Coupon from "./Coupon"
import Presale from "./Presale"
import Service from "./Service"
import Title from "./Title"
import Blank from "./Blank"
import AdjuncLine from "./AdjuncLine"
import RichText from "./RichText"
import Activity from "./Activity"
export default {
    name: 'EditForm',
    components: {
        Swiper,
        Search,
        Tabs,
        MakeUpComponent,
        GoodsList,
        Recommend,
        Navigation,
        News,
        Images,
        Icon,
        Coupon,
        Presale,
        Activity,
        Service,
        Title,
        Blank,
        AdjuncLine,
        RichText
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            type: '',
            list: {
                swiper: {
                    tit: '轮播图',
                    com: 'Swiper'
                },
                search: {
                    tit: '搜索框',
                    com: 'Search'
                },
                tab: {
                    tit: '选项标签',
                    com: 'Tabs'
                },
                makeUpComponent: {
                    tit: '组合组件',
                    com: 'MakeUpComponent'
                },
                goodsList: {
                    tit: '商品列表',
                    com: 'GoodsList'
                },
                recommend: {
                    tit: '推荐组',
                    com: 'Recommend'
                },
                navigation: {
                    tit: '导航组',
                    com: 'Navigation'
                },
                news: {
                    tit: '新闻通知',
                    com: 'News'
                },
                images: {
                    tit: '图片魔方',
                    com: 'Images'
                },
                icon: {
                    tit: '图标替换',
                    com: 'Icon'
                },
                coupon: {
                    tit: '优惠券',
                    com: 'Coupon'
                },
                presale: {
                    tit: '预售',
                    com: 'Presale'
                },
                activity: {
                    tit: '活动',
                    com: 'Activity'
                },
                service: {
                    tit: '在线客服',
                    com: 'Service'
                },
                title: {
                    tit: '标题',
                    com: 'Title'
                },
                blank: {
                    tit: '辅助空白',
                    com: 'Blank'
                },
                adjuncLine: {
                    tit: '辅助线',
                    com: 'AdjuncLine'
                },
                richText: {
                    tit: '辅助线',
                    com: 'RichText'
                },
            },
        }
    },
    mounted() {
        console.log('12---3',this.data)
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.tab-content{
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding-left: 24px;
    color: #000;
    border-radius: 4px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}
  
</style>