<template>
  <div>
  <div class="divBox">
    <el-card class="mt14">
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        ref="refsTable"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
      <el-table-column type="selection" width="55" />
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column prop="name" label="活动名称" min-width="120" />
        <el-table-column prop="name" label="活动列表图" min-width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.primaryPicture"
                :preview-src-list="[scope.row.primaryPicture]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="活动轮播图" min-width="400">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.banner">
              <el-image
                v-for="(item, index) in scope.row.banner.split(',')"
                :key="index"
                style="width: 36px; height: 36px; margin-left: 8px"
                :src="item"
                :preview-src-list="[item]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="活动背景色" min-width="120">
          <template slot-scope="scope">
            <div
              class="colorBox"
              :style="{ backgroundColor: scope.row.backgroundColor }"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="支付方式" min-width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              {{
                scope.row.payType == 1
                  ? "积分"
                  : scope.row.payType == 2
                  ? "现金"
                  : "积分+现金"
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="排序" min-width="120" />
        <el-table-column prop="createTime" label="添加时间" min-width="120" />

        <el-table-column prop="sort" label="活动时间时间" min-width="120">
          <template slot-scope="scope">
            <div>
              {{ `${scope.row.startTime}至${scope.row.endTime}` }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
  </div>
  <div class="footBox">
      <el-button type="primary" size="mini" @click="save">确定</el-button>
    </div>
  </div>
</template>
<script>
import {
  activityList,
  activityAdd,
  activityStateUp,
  activityDel,
  activityUp,
} from "@/api/market/activity";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { getIndex } from "@/utils/other";
export default {
  name: "banner",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      vLoading:false,
      title: "新增活动",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        state:1,
        storeId: "",
      },
      storeList: {},
      listLoading: false,
      selectionOne:[],
      tableData: {
        data: [{}],
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
  
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    //获取门店接口
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      activityList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false; 
        });
    },
    save() {
      if (this.selectionOne.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.$emit("selectItem", this.selectionOne[0]);
    },
    
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.colorBox {
  width: 34px;
  height: 34px;
}
.footBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>
  