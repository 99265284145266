<template>
  <div>
    <div class="product_tabs">
      <div slot="title">
        <div style="float: left">
          <el-button
            icon="el-icon-arrow-left"
            type="text"
            size="small"
            @click="goBack"
            >返回</el-button
          >
        </div>
        <div>
          <el-button
            type="primary"
            size="small"
            @click="submit"
            :loading="listLoading"
            >保存</el-button
          >
          <el-button class="ml20" size="small" @click="clear">重置</el-button>
        </div>
      </div>
    </div>
    <section class="decoration-edit" @dragover="dragOverPage">
      <section class="l">
        <div class="cell" @click="openL(1)">
          基础组件 <i class="icon el-icon-arrow-down"></i>
        </div>
        <ul
          @dragstart="dragStart"
          @dragend="dragEnd"
          v-if="typeAct.includes(1)"
        >
          <template v-for="(val, key, index) in typeList">
            <li
              :draggable="val.draggable"
              animation="300"
              :data-type="key"
              :key="index + 1"
              v-if="val.type == 1"
              @click="addView(val, index)"
            >
              <img
                draggable="false"
                :src="val.icon"
                style="width: 40px; height: 40px"
              />
              <div style="height: 120px; text-align: center">
                <p>{{ val.name }}</p>
                <p v-if="val.name === '组合组件'">(推荐)</p>
              </div>
            </li>
          </template>
        </ul>
        <div class="cell" @click="openL(2)">
          工具栏 <i class="icon el-icon-arrow-down"></i>
        </div>
        <ul
          @dragstart="dragStart"
          @dragend="dragEnd"
          v-if="typeAct.includes(2)"
        >
          <template v-for="(val, key, index) in typeList">
            <li
              :draggable="val.draggable"
              v-if="val.type == 2"
              :data-type="key"
              :key="index + 1"
              @click="addView(val, index)"
            >
              <img
                draggable="false"
                :src="val.icon"
                style="width: 40px; height: 40px"
              />
              <p>{{ val.name }}</p>
            </li>
          </template>
        </ul>
        <div class="cell" @click="openL(3)">
          营销组件 <i class="icon el-icon-arrow-down"></i>
        </div>
        <ul
          @dragstart="dragStart"
          @dragend="dragEnd"
          v-if="typeAct.includes(3)"
        >
          <template v-for="(val, key, index) in typeList">
            <li
              v-if="val.type == 3"
              :draggable="val.draggable"
              :data-type="key"
              :key="index + 1"
              @click="addView(val, index)"
            >
              <img
                draggable="false"
                :src="val.icon"
                style="width: 40px; height: 40px"
              />
              <p>{{ val.name }}</p>
            </li>
          </template>
        </ul>
        <div class="cell" @click="openL(4)">
          工具组件 <i class="icon el-icon-arrow-down"></i>
        </div>
        <ul
          @dragstart="dragStart"
          @dragend="dragEnd"
          v-if="typeAct.includes(4)"
        >
          <template v-for="(val, key, index) in typeList">
            <li
              v-if="val.type == 4"
              :draggable="val.draggable"
              :data-type="key"
              :key="index + 1"
              @click="addView(val, index)"
            >
              <img
                draggable="false"
                :src="val.icon"
                style="width: 40px; height: 40px"
              />
              <p>{{ val.name }}</p>
            </li>
          </template>
        </ul>
      </section>
      <div class="cBox">
        <section class="c">
          <div class="top-nav" @click="selectType(0)"></div>
          <div class="view-content" @drop="drog" @dragover="dragOver">
            <Draggable
              v-model="view"
              animation="300"
              draggable=".item"
              @change="changeList"
            >
              <template v-for="(item, index) in view">
                <template v-if="index > 0">
                  <template
                    v-if="
                      item.type != 'search' &&
                      item.type != 'makeUpComponent' &&
                      item.type != 'service' &&
                      item.type != 'icon'
                    "
                  >
                    <div
                      :data-index="index"
                      :key="item.num"
                      :class="
                        actIndex == index
                          ? 'item itemStyles active'
                          : 'item itemStyles'
                      "
                      @click="selectType(index)"
                    >
                      <span class="rightTab" v-if="actIndex == index">
                        <span class="rightTabItem" @click="del(index)"
                          ><i class="el-icon-delete"></i
                        ></span>
                        <span class="rightTabItem" @click="copy(item, index)"
                          ><i class="el-icon-document-add"></i
                        ></span>
                        <span class="rightTabItem" @click="up(index)"
                          ><i class="el-icon-arrow-up"></i
                        ></span>
                        <span class="rightTabItem" @click="down(index)"
                          ><i class="el-icon-arrow-down"></i
                        ></span>
                      </span>

                      <!-- waiting -->
                      <template v-if="item.status && item.status == 2">
                        <div class="wait">{{ item.type }}</div>
                      </template>
                      <template v-else>
                        <component
                          :index="index"
                          :is="typeList[item.type]['com']"
                          :data="item"
                          :num="item.num"
                        ></component>
                      </template>
                      <i
                        @click="deleteItem($event, index)"
                        class="el-icon-error"
                      ></i>
                    </div>
                  </template>

                  <template v-else>
                    <template
                      v-if="item.type != 'service' && item.type != 'icon'"
                    >
                      <div
                        :data-index="index"
                        :key="item.num"
                        :class="
                          actIndex == index ? 'itemStyles active' : 'itemStyles'
                        "
                        @click="selectType(index)"
                      >
                        <span class="rightTab" v-if="actIndex == index">
                          <span class="rightTabItem" @click="del(index)"
                            ><i class="el-icon-delete"></i
                          ></span>
                        </span>

                        <!-- waiting -->
                        <template v-if="item.status && item.status == 2">
                          <div class="wait">{{ item.type }}</div>
                        </template>
                        <template v-else>
                          <component
                            :index="index"
                            :is="typeList[item.type]['com']"
                            :data="item"
                            :num="item.num"
                          ></component>
                        </template>
                        <i
                          @click="deleteItem($event, index)"
                          class="el-icon-error"
                        ></i>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </Draggable>
            <template v-for="(item, index) in view">
              <template v-if="item.type == 'service'">
                <div :data-index="index" :key="item.num" class="serviceVBox">
                  <div
                    :class="
                      actIndex == index ? 'itemStyles active' : 'itemStyles'
                    "
                    style="background: none"
                    @click="selectType(index)"
                  >
                    <span class="rightTab" v-if="actIndex == index">
                      <span class="rightTabItem" @click="del(index)"
                        ><i class="el-icon-delete"></i
                      ></span>
                    </span>

                    <!-- waiting -->
                    <template v-if="item.status && item.status == 2">
                      <div class="wait">{{ item.type }}</div>
                    </template>
                    <template v-else>
                      <component
                        :index="index"
                        :is="typeList[item.type]['com']"
                        :data="item"
                        :num="item.num"
                      ></component>
                    </template>
                    <i
                      @click="deleteItem($event, index)"
                      class="el-icon-error"
                    ></i>
                  </div>
                </div>
              </template>

              <template v-if="item.type == 'icon'">
                <div :data-index="index" :key="item.num" class="iconViewBox">
                  <div
                    :class="
                      actIndex == index ? 'itemStyles active' : 'itemStyles'
                    "
                    style="background: none"
                    @click="selectType(index)"
                  >
                    <span class="rightTab" v-if="actIndex == index">
                      <span class="rightTabItem" @click="del(index)"
                        ><i class="el-icon-delete"></i
                      ></span>
                    </span>

                    <!-- waiting -->
                    <template v-if="item.status && item.status == 2">
                      <div class="wait">{{ item.type }}</div>
                    </template>
                    <template v-else>
                      <component
                        :index="index"
                        :is="typeList[item.type]['com']"
                        :data="item"
                        :num="item.num"
                      ></component>
                    </template>
                    <i
                      @click="deleteItem($event, index)"
                      class="el-icon-error"
                    ></i>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </section>
      </div>
      <section class="r" style="overflow-y: auto; overflow-x: hidden">
        <EditForm :data="props" v-if="isRight" />
      </section>
    </section>

    <el-dialog title="模板名称" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="80px">
        <el-form-item label="模板名称">
          <el-input v-model="name"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: row-reverse">
        <el-button type="primary" style="margin-left: 20px" @click="saveConfig"
          >确定</el-button
        >
        <el-button @click="name = ''">取消</el-button>
      </div>
    </el-dialog>
  </div> 
</template>

<script>
import { configAdd, getConfigView, configUp,decorationTemplate,getDecorationTemplate } from "@/api/decorate/decorate";
import Draggable from "vuedraggable";
import { mapState } from "vuex";
// import { imageUpload } from '@/api/commodity'
import Swiper from "./components/View/Swiper";
import Activity from "./components/View/Activity";
import Search from "./components/View/Search";
import Tabs from "./components/View/Tabs";
import MakeUpComponent from "./components/View/MakeUpComponent";
import GoodsList from "./components/View/GoodsList";
import Recommend from "./components/View/Recommend";
import Navigation from "./components/View/Navigation";
import News from "./components/View/News";
import Icon from "./components/View/Icon";
import Coupon from "./components/View/Coupon";
import Presale from "./components/View/Presale";
import Service from "./components/View/Service";
import Title from "./components/View/Title";
import Blank from "./components/View/Blank";
import AdjuncLine from "./components/View/AdjuncLine";
import RichText from "./components/View/RichText";

import EditForm from "./components/Edit/index";
import Product from "./components/View/Product";
import Images from "./components/View/Images";

import swiperIcon from "@/assets/icon/swiper.png";
import searchIcon from "@/assets/icon/search.png";
import tabIcon from "@/assets/icon/tab.png";
import makeUpComponentIcon from "@/assets/icon/makeUpComponent.png";
import goodsListIcon from "@/assets/icon/goodsList.png";
import recommendIcon from "@/assets/icon/recommend.png";
import navigationIcon from "@/assets/icon/navigation.png";
import newsIcon from "@/assets/icon/news.png";
import imagesIcon from "@/assets/icon/images.png";
import iconIcon from "@/assets/icon/icon.png";
import presaleIcon from "@/assets/icon/presale.png";
import activityIcon from "@/assets/icon/activity.png";
import serviceIcon from "@/assets/icon/service.png";
import titleIcon from "@/assets/icon/title.png";
import blankIcon from "@/assets/icon/blank.png";
import adjuncLineIcon from "@/assets/icon/adjuncLine.png";
import richTextIcon from "@/assets/icon/richText.png";
export default {
  components: {
    EditForm,
    Draggable,
    Product,
    Images,

    Swiper,
    Tabs,
    MakeUpComponent,
    GoodsList,
    Navigation,
    News,
    Icon,
    Coupon,
    Presale,
    Activity,
    Service,
    Title,
    AdjuncLine,
    RichText,
  },
  data() {
    return {
      name: "", //模板名称
      listLoading: false,
      typeAct: [1, 2, 3, 4],
      dialogVisible: false,
      typeList: {
        swiper: {
          comName: "swiper",
          name: "轮播图",
          icon: swiperIcon,
          com: Swiper,
          type: 1,
          draggable: true,
        },
        search: {
          comName: "search",
          name: "搜索框",
          icon: searchIcon,
          com: Search,
          type: 1,
          draggable: false,
        },
        tab: {
          comName: "tab",
          name: "选项标签",
          icon: tabIcon,
          com: Tabs,
          type: 1,
          draggable: true,
        },
        makeUpComponent: {
          comName: "makeUpComponent",
          name: "组合组件",
          icon: makeUpComponentIcon,
          com: MakeUpComponent,
          type: 1,
          draggable: false,
        },
        goodsList: {
          comName: "goodsList",
          name: "商品列表",
          icon: goodsListIcon,
          com: GoodsList,
          type: 1,
          draggable: true,
        },
        recommend: {
          comName: "recommend",
          name: "推荐组",
          icon: recommendIcon,
          com: Recommend,
          type: 1,
          draggable: true,
        },
        navigation: {
          comName: "navigation",
          name: "导航组",
          icon: navigationIcon,
          com: Navigation,
          type: 1,
          draggable: true,
        },
        news: {
          comName: "news",
          name: "新闻通知",
          icon: newsIcon,
          com: News,
          type: 1,
          draggable: true,
        },
        images: {
          comName: "images",
          name: "图片魔方",
          icon: imagesIcon,
          com: Images,
          type: 1,
          draggable: true,
        },
        icon: {
          comName: "icon",
          name: "图标替换",
          icon: iconIcon,
          com: Icon,
          type: 2,
          draggable: false,
        },
        // coupon: {
        //   comName: "coupon",
        //   name: "优惠券",
        //   icon: "el-icon-picture",
        //   com: Coupon,
        //   type: 3,
        //   draggable: true,
        // },
        presale: {
          comName: "presale",
          name: "预售",
          icon: presaleIcon,
          com: Presale,
          type: 3,
          draggable: true,
        },
        activity: {
          comName: "activity",
          name: "活动",
          icon: activityIcon,
          com: Activity,
          type: 3,
          draggable: true,
        },
        service: {
          comName: "service",
          name: "在线客服",
          icon: serviceIcon,
          com: Service,
          type: 4,
          draggable: false,
        },
        title: {
          comName: "title",
          name: "标题",
          icon: titleIcon,
          com: Title,
          type: 4,
          draggable: true,
        },
        blank: {
          comName: "blank",
          name: "辅助空白",
          icon: blankIcon,
          com: Blank,
          type: 4,
          draggable: true,
        },
        adjuncLine: {
          comName: "adjuncLine",
          name: "辅助线",
          icon: adjuncLineIcon,
          com: AdjuncLine,
          type: 4,
          draggable: true,
        },
        richText: {
          comName: "richText",
          name: "富文本",
          icon: richTextIcon,
          com: RichText,
          type: 4,
          draggable: true,
        },
      },
      view: [
        {
          type: "info",
          title: "页面标题",
          num: 12312,
        },
      ],
      title: "页面标题",
      type: "",
      index: null, // 当前组件的索引
      isPush: false, // 是否已添加组件
      props: {}, // 传值
      isRight: false,
      actIndex: null,
      pageId: "", //页面模板的id
    };
  },
  computed: {
    info() {
      return this.view[0];
    },
    pageInfo() {
      return this.$store.state.pagesCustom.pageInfo;
    },
  },
  watch: {
    // 监听计算属性，这样当someData变化时，会触发watcher
    pageInfo: {
      handler(nval, oval) {
        this.view = this.$store.state.pagesCustom.pageInfo;
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
  },
  mounted() {
    this.clear();
    this.pageId = this.$route.query.id;
    if (this.pageId) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      getConfigView(this.pageId).then((res) => {
        this.name = res.data.name;
        let arr = [
          {
            type: "info",
            title: "页面标题",
          },
        ];
        this.view = arr.concat(JSON.parse(res.data.content));
        console.log('1231',this.view)
        this.getShopList()
    
      });
    },
    getShopList(){
      getDecorationTemplate(
        { 
          pageDecorateId:this.pageId,
          storeId: this.$store.state.user.userInfo.storeId,
        }
      ).then((res) => {
        console.log('123records',res.data.records)
        this.view.map((item,index)=>{
          res.data.records.map((items,indexs)=>{
            if(item.num == items.key){
              item.content.list = items.list
            }
          })
        })

        this.$nextTick(() => {
          this.selectType(1);
        });
      });
    },
    clear() {
      let arr = [
        {
          type: "info",
          title: "页面标题",
        },
      ];
      console.log("1231---");
      this.$store.commit("setPage", arr);
      this.$nextTick(() => {
        let obj = {
          comName: "makeUpComponent",
          name: "组合组件",
          icon: makeUpComponentIcon,
          com: MakeUpComponent,
          type: 1,
          draggable: false,
        };
        this.addView(obj);
      });
    },
    openL(val) {
      if (this.typeAct.includes(val)) {
        this.typeAct = this.typeAct.filter((ele) => ele !== val);
      } else {
        this.typeAct.push(val);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    del(index) {
      this.view.splice(index, 1);
    },
    copy(item, index) {
      if (
        item.type == "makeUpComponent" ||
        item.type == "service" ||
        item.type == "search"
      ) {
        this.$message.error("该组件只能存在一个");
        return;
      }

      let beforArr = JSON.parse(JSON.stringify(this.view)).slice(0, index);
      let afterArr = JSON.parse(JSON.stringify(this.view)).slice(index);
      let arr = [];
      let obj = item;
      (obj.num = new Date().getTime() * 1000 + obj.type), arr.push(obj);
      this.view = [...beforArr, ...arr, ...afterArr];
      this.$store.commit("setPage", this.view);
    },
    up(index) {
      console.log("123", index);
      if (index != 1) {
        // 上移第一项无需上移
        if (
          this.view[index - 1].type == "makeUpComponent" ||
          this.view[index - 1].type == "service" ||
          this.view[index - 1].type == "search" ||
          this.view[index - 1].type == "icon"
        ) {
          return;
        }

        this.view.splice(index - 1, 2, this.view[index], this.view[index - 1]);
      }
    },
    down(index) {
      //最后一项无法向下移动
      if (index == this.view.length - 1) {
        return;
      }
      if (
        this.view[index].type == "makeUpComponent" ||
        this.view[index].type == "service" ||
        this.view[index].type == "search"
      ) {
        return;
      }
      console.log("index", index);
      if (index != this.view.length) {
        this.view.splice(index, 2, this.view[index + 1], this.view[index]);
      }
    },
    submit() {
      // JSON 转换会丢失 formData
      const form = JSON.parse(JSON.stringify(this.view));
      console.log("123", form);
      if (form.length == 1) {
        this.$message.error("请添加模块！");
        return;
      }
      let arr = form.filter(
        (ele) => ele.type == "makeUpComponent" || ele.type == "search"
      );
      if (arr.length == 0) {
        this.$message.error("请添加组合组件或者搜索框");
        return;
      }
      for (let i of form) {
        if (i.type == "swiper") {
          let swiperImg = i.content.list.filter((ele) => !ele.img);
          if (swiperImg.length > 0) {
            this.$message.error("轮播图图片不能为空");
            return;
          }
        }
        if(i.type == "search"&&!i.content.title){
          this.$message.error("搜索框名称不能为空");
            return
        }

        if (i.type == "makeUpComponent") {
          if (!i.content.search.title) {
            this.$message.error("组合组件搜索框名称不能为空");
            return;
          }
          let swiperImg = i.content.swiper.list.filter((ele) => !ele.img);
          if (swiperImg.length > 0) {
            this.$message.error("组合组件轮播图图片不能为空");
            return;
          }
        }

        if (i.type == "recommend") {
          let recommendImg = i.content.list.filter((ele) => !ele.img);
          if (recommendImg.length > 0) {
            this.$message.error("推荐组图片不能为空");
            return;
          }
        }

        if (i.type == "navigation") {
          let navigationImg = i.content.list.filter((ele) => !ele.img);
          if (navigationImg.length > 0) {
            this.$message.error("导航组图片不能为空");
            return;
          }
        }

        if (i.type == "news" && !i.content.img) {
          this.$message.error("新闻通知图片不能为空");
          return;
        }

        if (i.type == "images") {
          let imagesImg = i.content.list.filter((ele) => !ele.img);
          if (imagesImg.length > 0) {
            this.$message.error("图片魔方图片不能为空");
            return;
          }
        }

        if (i.type == "icon") {
          let iconImg = i.content.list.filter(
            (ele) => !ele.select || !ele.unchecked
          );
          if (iconImg.length > 0) {
            this.$message.error("图标替换图片不能为空");
            return;
          }
        }

        if (i.type == "activity") {
          let activityImg = i.content.list.filter((ele) => !ele.img);
          if (activityImg.length > 0) {
            this.$message.error("活动图片不能为空");
            return;
          }
        }
        if (i.type == "service" && !i.content.img) {
          this.$message.error("在线客服图片不能为空");
          return;
        }
      }

      this.dialogVisible = true;
      return;
    },
    saveConfig() {
      if (!this.name) {
        this.$message.error("请输入昵称");
        return;
      }

      let arr = JSON.parse(JSON.stringify(this.view));
      console.log("123arrarr", arr);
      arr.shift();
      //自定义商品走接口防止商品过期
      if (this.pageId) {
        this.listLoading = true;
        configUp({
          name: this.name,
          content: JSON.stringify(arr),
          id: this.pageId,
        })
          .then((res) => {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.name = "";
            this.listLoading = false;
            this.saveShopList(this.pageId)
          })
          .catch((res) => {
            this.listLoading = false;
          });
      } else {
        this.listLoading = true;
        configAdd({ name: this.name, content: JSON.stringify(arr) })
          .then((res) => {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.name = "";
            this.listLoading = false;
            this.saveShopList(res.data)
          })
          .catch((res) => {
            this.listLoading = false;
          });
      }
    },
    saveShopList(pageId){
      let pagelist = JSON.parse(JSON.stringify(this.view));
      let goodsListByShop = []
      let goodsList = pagelist.filter((ele) => ele.type == "goodsList"&&ele.content.sort=="custom")
       if(goodsList.length>0){
        goodsList.map((item,index)=>{
          let obj = {
            key:item.num,
            list:item.content.list.map(ele => ele.id)
          }
          goodsListByShop.push(obj)
        })
        console.log('1231',goodsListByShop)

        decorationTemplate({ pageDecorateId:pageId,arrays:goodsListByShop })
          .then((res) => {
            this.$router.go(-1);
          })
          .catch((res) => {
          });
       }else{
        this.$router.go(-1);
       }
    },
    //拖动改变排序
    changeList(evt) {
      this.actIndex = evt.moved.newIndex;
      this.selectType(this.actIndex);
    },
    // 切换视图组件
    selectType(index) {
      this.actIndex = index;
      this.isRight = false;
      this.props = this.view[index];
      this.$store.commit("setPage", this.view);
      this.$nextTick(() => (this.isRight = true));
    },
    deleteItem(e, index) {
      e.preventDefault();
      e.stopPropagation();
      this.view.splice(index, 1);
      this.isRight = false;
      this.props = {};
    },
    // 拖拽类型
    dragStart(e) {
      console.log("231", e.target.dataset.type);
      this.type = e.target.dataset.type;
    },
    onMove(e) {
      console.log("onMove", e);
    },
    addView(e, index) {
      let serviceList = this.view.filter((res) => res.type == "service");
      if (serviceList.length > 0 && e.comName == "service") {
        this.$message.error("只能有一个客服组件");
        return;
      }

      let iconList = this.view.filter((res) => res.type == "icon");
      if (iconList.length > 0 && e.comName == "icon") {
        this.$message.error("只能有一个图标组件");
        return;
      }

      let makeUpList = this.view.filter((res) => res.type == "makeUpComponent");
      if (makeUpList.length > 0) {
        if (e.comName == "makeUpComponent") {
          this.$message.error("只能有1一个组合组件");
          return;
        }
        if (e.comName == "search") {
          this.$message.error("组合组件和搜索框不能同时存在");
          return;
        }
      }
      let searchList = this.view.filter((res) => res.type == "search");

      if (searchList.length > 0) {
        if (e.comName == "makeUpComponent") {
          this.$message.error("组合组件和搜索框不能同时存在");
          return;
        }
        if (e.comName == "search") {
          this.$message.error("只能有一个搜索框");
          return;
        }
      }
      console.log("123", e.comName);
      let obj = {
        type: e.comName, // 组件类型
        // data: [], // 数据
        options: {}, // 选项操作
        num: new Date().getTime() * 1000 + e.comName,
      };
      if (e.comName == "search") {
        let beforArr = JSON.parse(JSON.stringify(this.view)).slice(0, 1);
        let afterArr = JSON.parse(JSON.stringify(this.view)).slice(1);
        this.view = [...beforArr, ...[obj], ...afterArr];
        this.selectType(1);
      } else if (e.comName == "makeUpComponent") {
        let beforArr = JSON.parse(JSON.stringify(this.view)).slice(0, 1);
        let afterArr = JSON.parse(JSON.stringify(this.view)).slice(1);
        this.view = [...beforArr, ...[obj], ...afterArr];
        this.selectType(1);
      } else {
        this.view.push(obj);
        this.selectType(this.view.length - 1);
      }

      this.sortView();
    },
    // 结束拖拽
    dragEnd() {
      this.$delete(this.view[this.actIndex], "status");
      this.isPush = false;
      this.type = null;
      this.selectType(this.actIndex);
      this.$store.commit("setPage", this.view);
      //重新排序
      this.sortView();
    },
    sortView() {
      console.log("1321", this.view);
      let arr1 = this.view.filter((item) => item.type == "info");
      let arr2 = this.view.filter(
        (item) =>
          item.type == "search" ||
          item.type == "makeUpComponent" ||
          item.type == "icon" ||
          item.type == "service"
      );
      let arr3 = this.view.filter(
        (item) =>
          item.type != "info" &&
          item.type != "search" &&
          item.type != "makeUpComponent" &&
          item.type != "icon" &&
          item.type != "service"
      );
      this.view = [...arr1, ...arr2, ...arr3];
      this.$store.commit("setPage", this.view);
    },
    // 已放置到指定位置
    drog(e) {
      if (!this.type) {
        // 内容拖拽
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      this.dragEnd();
    },
    dragOverPage() {
      if (this.type) {
        this.view = this.view.filter((res) => res.status != 2);
        this.isPush = false;
      }
    },
    // 移动中
    dragOver(e) {
      //是否有组合组件
      if (!this.type) {
        // 内容拖拽
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      let className = e.target.className;
      let name = className !== "view-content" ? "item" : "view-content";

      const defaultData = {
        type: this.type, // 组件类型
        status: 2, // 默认状态
        // data: [], // 数据
        options: {}, // 选项操作
        num: new Date().getTime() * 1000 + this.type,
      };

      if (name == "view-content") {
        if (!this.isPush) {
          this.actIndex = this.view.length;
          this.isPush = true;
          this.view.push(defaultData);
        }
      } else if (name == "item") {
        let target = e.target;
        let [y, h, curIndex] = [
          e.offsetY,
          target.offsetHeight,
          target.dataset.index,
        ];
        let direction = y < h / 2;

        if (!this.isPush) {
          // Push to Top or Bottom
          if (direction) {
            if (curIndex == 0) {
              this.view.unshift(defaultData);
            } else {
              this.view.splice(curIndex, 0, defaultData);
            }
          } else {
            curIndex = +curIndex + 1;
            this.view.splice(curIndex, 0, defaultData);
          }
        } else {
          if (direction) {
            var i = curIndex == 0 ? 0 : curIndex - 1;
            var result = this.view[i]["status"] == 2;
          } else {
            var i = +curIndex + 1;
            var result = this.view.length > i && this.view[i]["status"] == 2;
          }

          if (result) return;

          const temp = this.view.splice(this.actIndex, 1);
          this.view.splice(curIndex, 0, temp[0]);
        }
        this.actIndex = curIndex;
        this.isPush = true;
      }
    },
  },
};
</script>
<style lang="scss">
.decoration-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8f9;
  height: calc(100vh - 140px);
  position: relative;
  z-index: 1;
  .l,
  .r {
    width: 396px;
    height: 100%;
    padding: 15px 0;
    box-sizing: border-box;
    background: #fff;
  }
  .l {
    width: 370px;
    min-width: 370px;
    box-sizing: border-box;
    ul {
      margin: 0;
      padding: 0;
      li {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: default;
        list-style: none;
        font-size: 14px;
        color: #666;
        float: left;
        margin: 0 10px;
        border-radius: 6px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background: #efefef;
        }
        span {
          display: block;
          font-size: 40px;
          margin-bottom: 8px;
          color: #999;
        }
        p {
          display: block;
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
  .c {
    width: 475px;
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    .top-nav {
      position: absolute;
      top: 0;
      background: #fff;
      z-index: 999;
      transition: all 0.3s;
      & * {
        pointer-events: none;
      }
      &:hover {
        transform: scale(0.95);
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 10px #afafaf;
      }
      .tit {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
      }
      img {
        max-width: 100%;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }
    .view-content {
      width: 375px;
      height: 700px;
      background: #f5f5f5;
      // overflow-y: auto;
      // overflow-x: hidden;
      // padding-top: 72px;
      box-shadow: 0 2px 6px #ccc;
      padding-bottom: 100px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #dbdbdb;
      }
      &::-webkit-scrollbar-track {
        background: #f6f6f6;
      }

      .itemStyles {
        height: max-content;
        position: relative;
        width: 100%;
        .rightTab {
          z-index: 100;
          display: block;
          position: absolute;
          right: -45px;
          top: 0;
          width: 36px;
          border-radius: 4px;
          background: #4073fa;
          cursor: pointer;
          color: #fff;
          font-weight: 700;
          text-align: center;
          padding: 4px 0;
          .rightTabItem {
            display: block;
            width: 100%;
            height: 27px;
            z-index: 10000;
          }
        }
        div {
          pointer-events: none;
        }
        .wait {
          display: block !important;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border: 1px dashed #4073fa;
          color: #4073fa;
          background-color: #edf4fb;
        }
        .el-icon-error {
          position: absolute;
          right: -10px;
          top: -6px;
          color: red;
          font-size: 25px;
          cursor: pointer;
          display: none;
          z-index: 9999;
        }
      }
    }
  }
  .submit-btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.product_tabs {
  padding: 16px 32px;
  background: #fff;
  border-bottom: 1px solid #e8eaec;
  text-align: right;
  margin-bottom: 1px;
}
.cell {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #000;
  cursor: pointer;
  padding-left: 17px;
  box-sizing: border-box;
  .icon {
    margin-right: 17px;
  }
}
.itemLBox {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itemL {
  width: 74px;
  height: 66px;
  background-color: #000;
  margin-top: 10px;
  display: block;
}
.active {
  border: 2px solid #4073fa;
  box-sizing: border-box;
}
.serviceVBox {
  position: absolute !important;
  bottom: 100px;
  right: 120px;
  width: 54px !important;
  height: 54px !important;
  background: none !important;
  z-index: 100000000;
}
.cBox {
  width: 475px;
  position: relative;
  margin-top: -40px;
}
.iconViewBox {
  position: absolute;
  width: 375px;
  height: 80px;
  bottom: -80px;
  left: 0;
  background-color: #fff;
  z-index: 100000;
}
</style>