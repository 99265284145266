<template>
  <div class="product-content">

      <div class="styles-box">
        <div class="styles-item">背景颜色</div>

        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGround"
          ></el-color-picker
          ><span class="reset" @click="content.styles.backGround = '#fff'" style="margin-right: 17px">重置</span>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">组件高度</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.height"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.height"
          ></el-input>
        </div>
      </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
export default {
  name: "Product",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
  },
  data() {
    return {
      activeName: "content",
      value: "",
      value1: "",
      color1: "",
      indicatorE: {
        circular: "圆形",
        line: "线",
        none: "无",
      },
      textPositionE: {
        left: "居左",
        center: "居中",
        right: "居右",
      },
      textStylesE: {
        normal: "正常",
        italic: "斜体",
        bold: "加粗",
      },
      content: {
        styles: {
          backGround: "#fff",//背景颜色
          height: 10,//高度
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    startChange(e) {
      if (!e) {
        this.content.styles.backGroundStart = "#fff";
      }
    },
    endChange(e) {
      if (!e) {
        this.content.styles.backGroundEnd = "#fff";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      this.content.list.splice(index, 1);
    },
    add() {
      let obj = {
        title: "",
        value: "",
      };
      this.content.list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 325px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
</style>