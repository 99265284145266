<template>
  <div class="product-content">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabBox">
      <el-tab-pane label="内容设置" name="content"></el-tab-pane>
      <el-tab-pane label="样式设置" name="styles"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'content'">
      <div class="listBox">
        <div class="rightConter">
          <div class="info-item">
            <span class="info-title">标题</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                v-model="content.title"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="info-item" style="margin-top: 10px">
            <span class="info-title">链接</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                disabled
                v-model="content.value"
                placeholder="请选择内容"
              >
                <template slot="append">
                  <el-button
                    slot="append"
                    @click="showLink = true"
                    icon="el-icon-arrow-right"
                  ></el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeName == 'styles'">
      
      <div class="styles-box">
        <div class="styles-item">背景颜色</div>

        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGround"
          ></el-color-picker
          ><span class="reset" @click="content.styles.backGround = '#fff'" style="margin-right: 17px">重置</span>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">字体颜色</div>

        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.fontColor"
          ></el-color-picker
          ><span class="reset" @click="content.styles.fontColor = '#282828'" style="margin-right: 17px">重置</span>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          文本位置
          <span>{{ textPositionE[content.styles.textPosition] }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.textPosition == 'left'
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.textPosition = 'left'"
          >
          <img class="iconImg" src="@/assets/icon/icon5.png"/>
          </div>
          <div
            :class="
              content.styles.textPosition == 'center'
                ? 'setting-itemAct'
                : 'setting-item'
            "
            @click="content.styles.textPosition = 'center'"
          >
          <img class="iconImg" src="@/assets/icon/icon6.png"/>
          </div>
          <div
            :class="
              content.styles.textPosition == 'right'
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.textPosition = 'right'"
          >
          <img class="iconImg" src="@/assets/icon/icon7.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          文本样式
          <span>{{ textStylesE[content.styles.textStyles] }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.textStyles == 'normal'
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.textStyles = 'normal'"
          >
          <img class="iconImg" src="@/assets/icon/A.png"/>
          </div>
          <div
            :class="
              content.styles.textStyles == 'bold'
                ? 'setting-itemAct'
                : 'setting-item'
            "
            @click="content.styles.textStyles = 'bold'"
          >
          <img class="iconImg" src="@/assets/icon/B.png"/>
          </div>
          <div
            :class="
              content.styles.textStyles == 'italic'
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.textStyles = 'italic'"
          >
          <img class="iconImg" src="@/assets/icon/I.png"/>
          </div>
        </div>
      </div>
      <div class="styles-box">
        <div class="styles-item">
          背景样式
          <span>{{ content.styles.borderRadius ? "圆角" : "直角" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.borderRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
          <img class="iconImg" src="@/assets/icon/icon1.png"/>
          </div>
          <div
            :class="
              !content.styles.borderRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
          <img class="iconImg" src="@/assets/icon/icon2.png"/>
          </div>
        </div>
      </div>



      <div class="styles-box">
        <div class="styles-item">背景边距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.paddingLorR"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.paddingLorR"
          ></el-input>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">文本大小</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.fontSize"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.fontSize"
          ></el-input>
        </div>
      </div>


      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>

    <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";
import Draggable from "vuedraggable";
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "Product",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
    ChangeLink
  },
  data() {
    return {
      showLink: false,
      activeName: "content",
      value: "",
      value1: "",
      color1: "",
      indicatorE: {
        circular: "圆形",
        line: "线",
        none: "无",
      },
      textPositionE: {
        left: "居左",
        center: "居中",
        right: "居右",
      },
      textStylesE: {
        normal: "正常",
        italic: "斜体",
        bold: "加粗",
      },
      content: {
        title: "标题",
        value: "",
        styles: {
          backGround: "#fff",//背景颜色
          fontColor:"#333",//背景颜色
          textPosition:'left',//文件位置 
          textStyles: "normal",//文本样式
          borderRadius:true,//背景样式
          paddingLorR: 10,//背景边距
          fontSize:12,//文本大小
          marginTop:0,//页面间距
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    changeUrl(val) {
      this.content.value = val;
      this.showLink = false;
    },
    startChange(e) {
      if (!e) {
        this.content.styles.backGroundStart = "#fff";
      }
    },
    endChange(e) {
      if (!e) {
        this.content.styles.backGroundEnd = "#fff";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      this.content.list.splice(index, 1);
    },
    add() {
      let obj = {
        title: "",
        value: "",
      };
      this.content.list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 325px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
</style>