<template>
  <div class="product-content">
    <!-- <div class="swBox">
      <div>是否显示：</div>
      <el-switch
        v-model="content.isShow"
      >
      </el-switch>
    </div> -->

    <div class="headTips">客服图标：80X80PX圆形PNG图片</div>
    <div class="listBox">
      <div class="imageBox" v-if="content.img">
            <i
              class="el-icon-error"
              @click="content.img=''"
              style="font-size: 22px"
            ></i>
            <img :src="content.img" @click="content.img=''" /> 
          </div>

          <div class="imageBox" v-else @click="show=true">
            <i
              class="el-icon-error"
              @click="content.img=''"
              style="font-size: 22px"
            ></i>
            <i class="el-icon-camera-solid" style="font-size: 30px"></i>
          </div>
    </div>
    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>
  </div>
</template>

<script>
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
export default {
  name: "Product",
  components: {
    ChangeMaterial,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show:false,
      content: {
        // isShow: true,
        img: "",
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    changwImg(val) {
      this.content.img = val;
      this.show = false;
    },
    colorChange(e) {
      if (!e) {
        this.content.styles.backGround = "#fff";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    add() {
      let obj = {
        img: "",
        title: "",
        value: "",
      };
      if (this.content.list.length < 10) {
        this.content.list.push(obj);
      } else {
        this.$message.error("最多可添加10张图片");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.swBox{
  display: flex;
  align-items:center;
  margin-top: 20px;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
</style>