<template>
  <div class="product-content">
    <p class="titleBox"><span>选择样式</span>{{ actEume[act] }}</p>

    <div class="tabBox">
      <div
        class="itemTab"
        :style="act == 'search' ? 'border:1px solid #4073FA;' : ''"
        @click="act = 'search'"
      >
        <img class="iconImg" src="@/assets/icon/icon8.png" />
      </div>
      <!-- <div
        class="itemTab"
        :style="act == 'tab' ? 'border:1px solid #4073FA;' : ''"
        @click="act = 'tab'"
      >
      <img class="iconImg" src="@/assets/icon/icon9.png" /> 
      </div> -->
      <div
        class="itemTab"
        :style="act == 'swiper' ? 'border:1px solid #4073FA;' : ''"
        @click="act = 'swiper'"
      >
      <img class="iconImg" src="@/assets/icon/icon26.png" />
      </div>
    </div>
    <!-- 搜索框 -->
    <div v-if="act == 'search'" class="searchBox">
      <div class="Tips" style="margin-top: 15px;margin-bottom: 15px;">设置名称</div>
      <el-input placeholder="请输入内容" v-model="content.search.title"></el-input>
      <!-- <div class="Tips" style="margin-top: 15px">设置logo</div>
      <div class="headTips">建议上传大小:宽152px，高60px</div>
      <div class="listBox">
        <div class="imageBox" v-if="content.search.img">
          <i
            class="el-icon-error"
            @click="content.search.img = ''"
            style="font-size: 22px"
          ></i>
          <img :src="content.search.img" />
        </div>
        <div class="imageBox" v-else @click="show = true">
          <i class="el-icon-camera-solid" style="font-size: 30px"></i>
        </div>
      </div> -->
      <div class="Tips" style="margin-top: 15px">搜索热词</div>
      <div class="headTips">热词最多20个词，鼠标拖拽左侧圆点可调整</div>
      <Draggable
        v-model="content.search.list"
        animation="300"
        draggable=".inputBox"
        handle=".dragBox"
      >
        <div
          class="inputBox"
          v-for="(item, index) in content.search.list"
          :key="index"
        >
          <div class="dragBox">
            <img src="@/assets/images/dian.png" />
          </div>
          <el-input
            class="rcInput"
            v-model="item.value"
            maxlength="10"
            placeholder="选填，不超过十个字"
          />
          <i
            class="el-icon-error"
            @click="delSearch(index)"
            style="font-size: 22px"
          ></i>
        </div>
      </Draggable>
      <el-button class="addBtn" v-if='content.search.list.length < 20' plain  @click="addSearch">添加热词</el-button>

      <!-- <div class="timeBox">
        显示时间：<el-input
          class="timeInput"
          placeholder="设置搜索热词显示时间"
          v-model="content.search.time"
        >
          <template slot="append">秒</template>
        </el-input>
      </div> -->
    </div>

    <!-- <div v-if="act == 'tab'" class="searchBox">
      <div
        class="listBox"
        v-for="(item, index) in content.tab.list"
        :key="index"
      >
        <div class="rightConter">
          <i
            class="el-icon-error"
            @click="delTab(index)"
            style="font-size: 22px"
          ></i>
          <div class="info-item">
            <span class="info-title">标题</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                v-model="item.title"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="info-item" style="margin-top: 10px">
            <span class="info-title">链接</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                disabled
                v-model="item.value"
                placeholder="请选择内容"
              >
                <template slot="append">
                  <el-button
                    @click="linkDio(index)"
                    slot="append"
                    icon="el-icon-arrow-right"
                  ></el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <el-button v-if='content.tab.list.length < 10' plain class="addBtn" @click="addTab">添加板块</el-button>
    </div> -->

    <div v-if="act == 'swiper'" class="searchBox">
      <Draggable
        v-model="content.swiper.list"
        animation="300"
        handle=".dragBoxSwp"
      >
        <div
          class="listBox"
          v-for="(item, index) in content.swiper.list"
          :key="index"
        >
          <div class="dragBoxSwp">
            <img src="@/assets/images/dian.png" />
          </div>
          <!-- <div class="imageBox">
            <i
              class="el-icon-error"
              @click="delswiper(index)"
              style="font-size: 22px"
            ></i>
            <i class="el-icon-camera-solid" style="font-size: 30px"></i>
          </div> -->

          
          <div class="imageBox" v-if="item.img">
            <i
              class="el-icon-error"
              @click="del(index)"
              style="font-size: 22px"
            ></i>
            <img :src="item.img" @click="showDio(index)" />
          </div>

          <div class="imageBox" v-else>
            <i
              class="el-icon-error"
              @click="del(index)"
              style="font-size: 22px"
            ></i>
            <i
              class="el-icon-camera-solid"
              @click="showDio(index)"
              style="font-size: 30px"
            ></i>
          </div>
          <div class="rightConter">
            <div class="info-item">
              <span class="info-title">标题</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  v-model="item.title"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="info-item" style="margin-top: 10px">
              <span class="info-title">链接</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  disabled
                  v-model="item.value"
                  placeholder="请选择内容"
                >
                  <template slot="append">
                    <el-button
                    @click="linkDio(index)"
                      slot="append"
                      icon="el-icon-arrow-right"
                    ></el-button>
                  </template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
      <el-button plain class="addBtn" v-if='content.swiper.list.length < 10' @click="addSwp">添加板块</el-button>
    </div>

    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>

    <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";
import Draggable from "vuedraggable";
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "MakeUpComponent",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
    ChangeMaterial,
    ChangeLink,
  },
  data() {
    return {
      clickIndex: null,
      show: false,
      showLink: false,
      actEume: {
        search: "搜索框 ",
        tab: "选项卡",
        swiper: "轮播图",
      },
      act: "search", //search搜索 tab选项卡 swiper轮播
      content: {
        search: {
          img: "",
          title:this.$store.state.user.userInfo.storeName,
          list: [
            {
              value: "",
            },
          ],
          // showTime: "",
        },
        tab: {
          list: [
            {
              title: "",
              value: "",
            },
          ],
        },
        swiper: {
          list: [
            {
              img: "",
              title: "",
              value: "",
            },
          ],
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    linkDio(index) {
      this.clickIndex = index;
      this.showLink = true;
    },
    showDio(index){
      this.clickIndex = index;
      this.show = true;
    },
    changwImg(val) {
      if (this.act == "search") {
        this.content.search.img = val;
      }
      if (this.act == "swiper") {
        this.content.swiper.list[this.clickIndex].img = val;
      }
      this.show = false;
    },
    changeUrl(val) {
      if (this.act == "tab") {
        this.content.tab.list[this.clickIndex].value = val;
        this.showLink = false;
      }
      if (this.act == "swiper") {
        this.content.swiper.list[this.clickIndex].value = val;
        this.showLink = false;
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
      this.$forceUpdate()
    },
    handleClick() {},
    delSearch(index) {
      this.content.search.list.splice(index, 1);
    },
    delTab(index) {
      this.content.tab.list.splice(index, 1);
    },
    delswiper(index) {
      this.content.swiper.list.splice(index, 1);
    },
    addSearch() {
      let obj = {
        value: "",
      };
      if (this.content.search.list.length < 20) {
        this.content.search.list.push(obj);
      } else {
        this.$message.error("最多可添加20个");
      }
    },

    addTab() {
      let obj = {
        title: "",
        value: "",
      };
      if (this.content.tab.list.length < 10) {
        this.content.tab.list.push(obj);
      } else {
        this.$message.error("最多可添加10个");
      }
    },
    addSwp() {
      let obj = {
        title: "",
        value: "",
      };
      if (this.content.swiper.list.length < 10) {
        this.content.swiper.list.push(obj);
      } else {
        this.$message.error("最多可添加10个");
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.inputBox {
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.el-icon-error {
  position: absolute;
  top: -8px;
  right: -8px;
  color: rgb(153, 153, 153);
}
.dragBox {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dragBoxSwp {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.rcInput {
  width: calc(100% - 36px);
}
.titleBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  span {
    color: #999;
    margin-right: 10px;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.itemTab {
  width: 67px;
  height: 34px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdee2;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  min-width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
  position: relative;
  .el-icon-error {
    position: absolute;
    right: -40px;
    top: 30px;
    z-index: 10000;
    cursor: pointer;
  }
}

.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #999;
  width: 40px;
}
.input-box {
  width: 100% !important;
  height: 32px !important;
}
.inputItem {
  width: 100% !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
.addBtn {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border: 1px solid #4073fa;
  color: #4073fa;
}
.timeBox {
  display: flex;
  margin-top: 15px;
  align-items: center;
  font-size: 14px;
  color: #606266;
}
.timeInput {
  width: calc(100% - 70px);
}
</style>