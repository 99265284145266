<template>
  <div class="product-content">
    <!-- <div class="styles-box">
        <div class="styles-item">背景颜色</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGround"
          ></el-color-picker
          ><span class="reset" style="margin-right: 17px" @click="content.styles.backGround='#fff'"
            >重置</span
          >
        </div>
      </div> -->

      <div class="styles-box">
        <div class="styles-item">未选中的颜色</div>
        <div class="setting-box">
          <el-color-picker v-model="content.styles.unCheckedColor" @change="unCheckedChange"></el-color-picker
          ><span class="reset" style="margin-right: 17px" @click="unCheckedChange()">重置</span>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">选中的颜色</div>
        <div class="setting-box">
          <el-color-picker v-model="content.styles.checkedColor" @change="checkedChange"></el-color-picker
          ><span class="reset" style="margin-right: 17px" @click="checkedChange()">重置</span>
        </div>
      </div>
      <!-- <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div> -->

      <div class="listBox" v-for="(item, index) in content.list" :key="index">

        <div class="rightConter">
          <i class="el-icon-error" @click="del(index)" style="font-size: 22px"></i>
          <div class="info-item">
            <span class="info-title">标题</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                v-model="item.title"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="info-item" style="margin-top: 10px">
            <span class="info-title">链接</span>
            <div class="input-box">
              <el-input
                class="inputItem"
                v-model="item.value"
                disabled
                placeholder="请选择内容"
              >
                <template slot="append">
                  <el-button
                    slot="append"
                    @click="linkDio(index)"
                    icon="el-icon-arrow-right"
                  ></el-button>
                </template>
              </el-input>
            </div>
          </div>

        </div>
      </div>
      <el-button v-if='content.list.length<10' plain style="width: 100%; height: 40px" @click="add"
        >添加板块</el-button
      >

      <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from 'element-ui';
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "Product",
  components: {
    ChangeLink,
  },
  props: {
    data: {
      type: Object,
      default: () => {}, 
    },
  },
  data() {
    return {
      showLink:false,
      content: {
        list: [
          {
            title: "标题",
            value: "",
          },
        ],
        clickIndex: null,
        styles: {
          checkedColor:'#fff',
          unCheckedColor:'#333', 
          // backGround:'#fff',
          // marginTop:5,
        },
      }, 
    };
  },
  watch: {
    content:{
      handler: function() {          
        this.uploadVuex()                  
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },

  },
  created() {
    if(this.data.content){
      this.content = this.data.content
    }
    this.uploadVuex()
  },
  methods: {
    linkDio(index) {
      this.clickIndex = index;
      this.showLink = true;
    },
    changeUrl(val) {
      this.content.list[this.clickIndex].value = val;
      this.showLink = false;
    },
    unCheckedChange(e){
      if(!e){
        this.content.styles.unCheckedColor = '#fff'
      }
    },
    checkedChange(e){
      if(!e){
        this.content.styles.checkedColor = '#fff'
      }
    },
    uploadVuex(){
      let arr = this.$store.state.pagesCustom.pageInfo
        arr.map((item)=>{
          if(item.num == this.data.num){
            item.content = this.content
          }
        })
        this.$store.commit("setPage", []);
        this.$store.commit("setPage", arr);
    },
    handleClick() {},
    add() {
      let obj = {
        img: "",
        title: "",
        value: "",
      };
      if (this.content.list.length < 10) {
        this.content.list.push(obj);
      }else{
        this.$message.error('最多可添加10张图片')
      }
    },
    del(index){
      if(this.content.list.length==1){
        this.$message.error('至少保留一个')
        return
      }
      this.content.list.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
  position: relative;
}
.el-icon-error{
  position: absolute;
  right: -40px;
  top: 30px;
  z-index: 10000;
  cursor: pointer;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #999;
  width: 40px;
}
.input-box {
  width: 255px !important;
  height: 32px !important;
}
.inputItem {
  width: 255px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073FA;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
</style>