var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-content"},[_c('div',[_c('div',[_c('p',{staticClass:"titleBox"},[_c('span',[_vm._v("选择样式")]),_vm._v(_vm._s(_vm.content.styles.stylesType == "one" ? "单行" : _vm.content.styles.stylesType == "two" ? "多行" : "模块")+" ")]),_c('div',{staticClass:"tabBox"},[_c('div',{staticClass:"itemTab",style:(_vm.content.styles.stylesType == 'one'
              ? 'border:1px solid #4073FA;'
              : ''),on:{"click":function($event){_vm.content.styles.stylesType = 'one'}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon15.png")}})]),_c('div',{staticClass:"itemTab",style:(_vm.content.styles.stylesType == 'two'
              ? 'border:1px solid #4073FA;'
              : ''),on:{"click":function($event){_vm.content.styles.stylesType = 'two'}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon16.png")}})]),_c('div',{staticClass:"itemTab",style:(_vm.content.styles.stylesType == 'templates'
              ? 'border:1px solid #4073FA;'
              : ''),on:{"click":function($event){_vm.content.styles.stylesType = 'templates'}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon20.png")}})])])]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v("主题风格")]),_c('div',{staticClass:"setting-box"},[_c('el-color-picker',{on:{"change":_vm.colorChange},model:{value:(_vm.content.styles.themeColor),callback:function ($$v) {_vm.$set(_vm.content.styles, "themeColor", $$v)},expression:"content.styles.themeColor"}}),_c('span',{staticClass:"reset",staticStyle:{"margin-right":"17px"},on:{"click":function($event){return _vm.colorChange()}}},[_vm._v("重置")])],1)]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v(" 背景样式 "),_c('span',[_vm._v(_vm._s(_vm.content.styles.backgroundRadius ? "圆角" : "直角"))])]),_c('div',{staticClass:"setting-box"},[_c('div',{class:_vm.content.styles.backgroundRadius
              ? 'setting-itemAct borderL'
              : 'setting-item borderL',on:{"click":function($event){_vm.content.styles.backgroundRadius = !_vm.content.styles.backgroundRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon1.png")}})]),_c('div',{class:!_vm.content.styles.backgroundRadius
              ? 'setting-itemAct borderR'
              : 'setting-item borderR',on:{"click":function($event){_vm.content.styles.backgroundRadius = !_vm.content.styles.backgroundRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon2.png")}})])])]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v(" 内容样式 "),_c('span',[_vm._v(_vm._s(_vm.content.styles.contentRadius ? "圆角" : "直角"))])]),_c('div',{staticClass:"setting-box"},[_c('div',{class:_vm.content.styles.contentRadius
              ? 'setting-itemAct borderL'
              : 'setting-item borderL',on:{"click":function($event){_vm.content.styles.contentRadius = !_vm.content.styles.contentRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon1.png")}})]),_c('div',{class:!_vm.content.styles.contentRadius
              ? 'setting-itemAct borderR'
              : 'setting-item borderR',on:{"click":function($event){_vm.content.styles.contentRadius = !_vm.content.styles.contentRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon2.png")}})])])]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v("页面间距")]),_c('div',{staticClass:"setting-box"},[_c('el-slider',{staticStyle:{"width":"120px"},model:{value:(_vm.content.styles.marginTop),callback:function ($$v) {_vm.$set(_vm.content.styles, "marginTop", $$v)},expression:"content.styles.marginTop"}}),_c('el-input',{staticClass:"sliderBox",model:{value:(_vm.content.styles.marginTop),callback:function ($$v) {_vm.$set(_vm.content.styles, "marginTop", $$v)},expression:"content.styles.marginTop"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }