<template>
  <div>
    <div class="divBox">
      <div class="table">
        <div>
          <el-table
            v-loading="listLoading"
            :data="tableData.data"
            size="small"
            ref="refsTable"
            highlight-current-row
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column type="index" width="50" />
            <el-table-column prop="title" label="标题" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="remark" label="状态">
              <template slot-scope="scope">
                <div>{{ scope.row.state == 1 ? "开启" : "关闭" }}</div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="block">
          <el-pagination
            background
            :page-size="tableFrom.pageSize"
            :current-page="tableFrom.pageNum"
            layout="total, prev, pager, next, jumper"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div> -->
        </div>
      </div>
    </div>
    <div class="footBox">
      <el-button type="primary" @click="save" size="mini">确定</el-button>
    </div>
  </div>
</template>
<script>
import { linkarticleStateUp } from "@/api/decorate/decorate";
export default {
  name: "articleCom",
  data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0,

      },
      selectionOne: [], //选中的数据
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        state:1
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },
    getList() {
      this.listLoading = true;
      linkarticleStateUp(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    save() {
      if (this.selectionOne.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.$emit("selectItem", this.selectionOne[0]);
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  min-height: 500px;
}
.view {
  min-height: 500px;
  height: 100%;
}
.table {
  width: 100%;
  height: 100;
  min-height: 600px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
.footBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
</style>