<template>
  <div>
    <div class="divBox">
      <el-form
        inline
        size="small"
        :model="tableFrom"
        ref="searchForm"
        label-width="120px"
      >
        <el-form-item label="商品名称：" prop="name">
          <el-input
            class="selWidth"
            v-model="tableFrom.name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table">
        <div>
          <el-table
            v-loading="listLoading"
            :data="tableData.data"
            size="small"
            ref="refsTable"
            highlight-current-row
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
             <el-table-column
              type="index"
              :index="getTabelIndex"
              width="100"
              label="序号"
            />
            <el-table-column label="商品图" min-width="70">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.mainImg"
                    :preview-src-list="[scope.row.mainImg]"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="200">
              <template slot-scope="scope">
                <div>
                  <span
                    class="tags_name"
                    :style="
                      scope.row.specType == 0
                        ? 'color: #ff8a4d;'
                        : 'color: #4073FA;'
                    "
                    :class="'name' + scope.row.spec_type"
                    >{{
                      scope.row.specType == 0 ? "[单规格]" : "[多规格]"
                    }}</span
                  >{{ scope.row.name || "-" }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              :page-size="tableFrom.pageSize"
              :current-page="tableFrom.pageNum"
              layout="total, prev, pager, next, jumper"
              :total="tableData.total"
              @size-change="handleSizeChange"
              @current-change="pageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footBox">
      <el-button type="primary" size="mini" @click="save">确定</el-button>
    </div>
  </div>
</template>
<script>
import { goodsStorePageList } from "@/api/goods/goods";
import { getIndex } from "@/utils/other";
export default {
  name: "goodsLink",
  data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      selectionOne:[], //选中的数据
      tableFrom: {
        name: "",
        pageNum: 1,
        status: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },
    search(){
     this.tableFrom.pageNum=1
     this.getList()
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    getList() {
      this.listLoading = true;
      goodsStorePageList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    save() {
      if (this.selectionOne.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.$emit("selectItem", this.selectionOne[0]);
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  min-height: 500px;
}
.view {
  min-height: 500px;
  height: 100%;
}
.table {
  width: 100%;
  height: 100;
  min-height: 600px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
.footBox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>