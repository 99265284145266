<template>
  <div class="product-content">
    <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%">
      <el-tab-pane label="内容设置" name="content"></el-tab-pane>
      <el-tab-pane label="样式设置" name="styles"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'content'">
      <!-- <p class="titleBox">
        <span>选择样式</span
        >{{ content.changeType == "auto" ? "自动选择" : "手动选择" }}
      </p> -->
      <!-- <div class="tabBox">
        <div
          class="itemTab"
          :style="
            content.changeType == 'auto' ? 'border:1px solid #4073FA;' : ''
          "
          @click="content.changeType = 'auto'"
        >
          <img src="@/assets/images/dian.png" />
        </div>
        <div
          class="itemTab"
          :style="
            content.changeType == 'manual' ? 'border:1px solid #4073FA;' : ''
          "
          @click="content.changeType = 'manual'"
        >
          <img src="@/assets/images/dian.png" />
        </div>
      </div> -->
      <!-- 自动选择 -->
      <div> 
        <!-- <div class="selectBox">
          <span>商品分类</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value" 
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="styles-box">
          <div class="styles-item">
            商品排序
            <span>{{
              content.sort == "default"
                ? "综合"
                : content.sort == "sales"
                ? "销量"
                : content.sort == "price"
                ? "价格"
                : "自定义"
            }}</span>
          </div>

          <div class="setting-box">
            <div
              :class="
                content.sort == 'default'
                  ? 'setting-itemAct borderL'
                  : 'setting-item borderL'
              "
              @click="content.sort = 'default'"
            >
              <img class="iconImg" src="@/assets/icon/icon10.png" />
            </div>
            <div
              :class="
                content.sort == 'sales'
                  ? 'setting-itemAct borderR'
                  : 'setting-item borderR'
              "
              @click="content.sort = 'sales'"
            >
              <img class="iconImg" src="@/assets/icon/icon11.png" />
            </div>
            <div
              :class="
                content.sort == 'price'
                  ? 'setting-itemAct borderR'
                  : 'setting-item borderR'
              "
              @click="content.sort = 'price'"
            >
              <img class="iconImg" src="@/assets/icon/icon12.png" />
            </div>
            <div
              :class="
                content.sort == 'custom'
                  ? 'setting-itemAct borderR'
                  : 'setting-item borderR'
              "
              @click="content.sort = 'custom'"
            >
              <img class="iconImg" src="@/assets/icon/icon7.png" />
            </div>
          </div>
        </div>

        <div class="selectBox" v-if="content.sort != 'custom'">
          <span>商品数量</span>
          <el-input-number
            style="width: 305px"
            v-model="content.num"
            :min="1"
            :max="100"
            label="商品数量"
          ></el-input-number>
        </div>
        <template v-else>
          <div class="headTips">最多可添加10个商品</div>
          <el-button plain style="width: 100%; height: 40px" @click="add"
            >新增商品</el-button
          >
          <div class="shopList">
            <Draggable
                v-model="content.list"
                animation="300"
                draggable=".shopItem" 
                class="shopList"
                handle=".shopImg"
              >
              <div
                v-for="(item, index) in content.list"
                :key="index"
                class="shopItem"
              >
                <i
                  class="el-icon-error"
                  @click.stop="del(index)"
                  style="font-size: 22px"
                ></i>
                <img class="shopImg" :src="item.mainImg" />
              </div>
            </Draggable>
          </div>
        </template>
      </div>
      <!-- <div v-else>
        <div class="imgBox">
          <div class="imgItem">
            <i class="el-icon-error"></i>
            <img
              src="https://demo.mall.ahweimeng.cn/uploads/copy/4a54b04c6889400ce1076b0e30320206.jpg"
            />
          </div>
          <div class="imgItem" style="background-color: #f7f7f7">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div> -->
    </div>

    <div v-if="activeName == 'styles'">
      <div class="styles-box">
        <div class="styles-item">背景颜色</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGroundColor"
            @change="backGroundChange"
          ></el-color-picker
          ><span
            class="reset"
            @click="backGroundChange('')"
            style="margin-right: 17px"
            >重置</span
          >
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">价格颜色</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.priceColor"
            @change="priceChange"
          ></el-color-picker
          ><span
            class="reset"
            @click="priceChange('')"
            style="margin-right: 17px"
            >重置</span
          >
        </div>
      </div>

      <!-- <div class="styles-box">
        <div class="styles-item">活动标签</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.actTab"
            @change="actTabChange"
          ></el-color-picker
          ><span class="reset"  @click="actTabChange('')" style="margin-right: 17px">重置</span>
        </div>
      </div> -->

      <div class="styles-box">
        <div class="styles-item">
          显示类型
          <span>{{ content.styles.showType == "one" ? "单列" : "双列" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.showType == 'one'
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.showType = 'one'"
          >
            <img class="iconImg" src="@/assets/icon/icon17.png" />
          </div>
          <div
            :class="
              content.styles.showType == 'two'
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.showType = 'two'"
          >
            <img class="iconImg" src="@/assets/icon/icon18.png" />
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          背景样式
          <span>{{ content.styles.backgroundRadius ? "圆角" : "直角" }}</span>
        </div>
        <div class="setting-box">
          <div
            :class="
              content.styles.backgroundRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="
              content.styles.backgroundRadius = !content.styles.backgroundRadius
            "
          >
            <img class="iconImg" src="@/assets/icon/icon1.png" />
          </div>
          <div
            :class="
              !content.styles.backgroundRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="
              content.styles.backgroundRadius = !content.styles.backgroundRadius
            "
          >
            <img class="iconImg" src="@/assets/icon/icon2.png" />
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          内容样式
          <span>{{ content.styles.contentRadius ? "圆角" : "直角" }}</span>
        </div>
        <div class="setting-box">
          <div
            :class="
              content.styles.contentRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="
              content.styles.contentRadius = !content.styles.contentRadius
            "
          >
            <img class="iconImg" src="@/assets/icon/icon1.png" />
          </div>
          <div
            :class="
              !content.styles.contentRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="
              content.styles.contentRadius = !content.styles.contentRadius
            "
          >
            <img class="iconImg" src="@/assets/icon/icon2.png" />
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择商品"
      :visible.sync="dialogGoodsVisible"
      append-to-body
      width="1100px"
    >
      <ChangeStoreGoods
        v-if="dialogGoodsVisible"
        @getGoodsItem="getGoodsItem"
      />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";
import Draggable from "vuedraggable";
import ChangeStoreGoods from "../changeStoreGoods";
export default {
  name: "Product",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
    ChangeStoreGoods,
  },
  data() {
    return {
      activeName: "content",
      dialogGoodsVisible: false,
      value1: "",
      color1: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      indicatorE: {
        circular: "圆形",
        line: "线",
        none: "无",
      },
      indicatorPositionE: {
        left: "居左",
        center: "居中",
        right: "居右",
      },
      content: {
        sort: "default", //default综合 sales销量 price价格 custom自定义
        num: 10,
        customId: "", //自定义商品的id
        list:[],
        styles: {
          backGroundColor: "#fff",
          priceColor: "#E93323",
          showType: "one", //one单two双
          backgroundRadius: true, //圆角
          contentRadius: true, //圆角
          marginTop: 0,
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    getGoodsItem(row) {
      let arr = this.content.list.concat(row);
      arr = this.uniqueByStringify(JSON.parse(JSON.stringify(arr)));
      if (arr.length > 10) {
        this.$message.error("最多只能选择10个商品");
        return;
      } else {
        this.content.list = arr;
      }
      this.dialogGoodsVisible = false;
    },
    uniqueByStringify(arr) {
      const uniqueSet = new Set();
      const result = [];
      arr.forEach((item) => {
        const strItem = JSON.stringify(item);
        if (!uniqueSet.has(strItem)) {
          uniqueSet.add(strItem);
          result.push(JSON.parse(strItem));
        }
      });
      return result;
    },
    backGroundChange(e) {
      console.log("123", e);
      if (!e) {
        this.content.styles.backGroundColor = "#fff";
      }
    },
    priceChange(e) {
      if (!e) {
        this.content.styles.priceColor = "#E93323";
      }
    },
    actTabChange(e) {
      if (!e) {
        this.content.styles.actTab = "#E93323";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      this.content.list.splice(index, 1);
    },
    add() {
      this.dialogGoodsVisible = true;
      // let obj = {
      //   title: "",
      //   value: "",
      // };
      // this.content.list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  span {
    color: #999;
    margin-right: 10px;
  }
}
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.tabBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.itemTab {
  width: 67px;
  height: 34px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdee2;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
.selectBox {
  display: flex;
  margin-top: 25px;
  align-items: center;
  span {
    color: #606266;
    font-size: 14px;
    margin-right: 15px;
  }
}
.imgBox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.imgItem {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-icon-error {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-icon-plus {
    font-size: 23px;
    color: #d8d8d8;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.shopList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.shopItem {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  position: relative;
  margin-top: 20px;
  margin-right: 13px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.33);
}
.shopImg {
  width: 100%;
  height: 100%;
  display: block;
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
</style>