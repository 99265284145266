<template>
  <div>
    <div class="product-content">
      <p class="titleBox">
        <span>选择样式</span>{{ stylesTypeE[content.stylesType] }}
      </p>
      <div class="tabBox">
        <div
          class="itemTab"
          :style="content.stylesType == 1 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(1)"
        >
          <img class="iconImg" src="@/assets/icon/icon17.png" />
        </div>
        <div
          class="itemTab"
          :style="content.stylesType == 2 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(2)"
        >
          <img class="iconImg" src="@/assets/icon/icon18.png" />
        </div>
        <div
          class="itemTab"
          :style="content.stylesType == 3 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(3)"
        >
          <img class="iconImg" src="@/assets/icon/icon19.png" />
        </div>
        <div
          class="itemTab"
          :style="content.stylesType == 4 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(4)"
        >
          <img class="iconImg" src="@/assets/icon/icon20.png" />
        </div>
        <div
          class="itemTab"
          :style="content.stylesType == 5 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(5)"
        >
        <img class="iconImg" src="@/assets/icon/icon21.png" />
        </div>
        <div
          class="itemTab"
          :style="content.stylesType == 6 ? 'border:1px solid #4073FA;' : ''"
          @click="stylesChange(6)"
        >
          <img class="iconImg" src="@/assets/icon/icon22.png" />
        </div>
      </div>

      <p class="boxTitle">布局</p>
      <p class="boxTitle2">
        选定布局区域，在下方添加图片，建议添加比例一致的图片
      </p>
      <div class="stylesOne" v-if="content.stylesType == 1">
        <span v-if="!content.list[0].img">尺寸不限</span>
        <img v-else class="imgItem" :src="content.list[0].img" />
      </div>
      <div class="stylesTwo" v-if="content.stylesType == 2">
        <div
          class="stylesTwoItem"
          :style="
            act === 0 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 0"
        >
          <template v-if="!content.list[0].img">
            <div>宽375像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[0].img" />
        </div>
        <div
          class="stylesTwoItem"
          :style="
            act === 1 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 1"
        >
          <template v-if="!content.list[1].img">
            <div>宽375像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[1].img" />
        </div>
      </div>
      <div class="stylesThree" v-if="content.stylesType == 3">
        <div
          class="stylesThreeItem"
          :style="
            act === 0 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 0"
        >
          <template v-if="!content.list[0].img">
            <div>宽250像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[0].img" />
        </div>
        <div
          class="stylesThreeItem"
          :style="
            act === 1 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 1"
        >
          <template v-if="!content.list[1].img">
            <div>宽250像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[1].img" />
        </div>
        <div
          class="stylesThreeItem"
          :style="
            act === 2 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 2"
        >
          <template v-if="!content.list[2].img">
            <div>宽250像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[2].img" />
        </div>
      </div>

      <div class="stylesFour" v-if="content.stylesType == 4">
        <div class="stylesFourItem">
          <div
            class="stylesFourItemOne"
            :style="
              act === 0 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 0"
          >
            <template v-if="!content.list[0].img">
              375*375像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[0].img" />
          </div>
        </div>
        <div class="stylesFourItem">
          <div
            class="stylesFourItemTwo"
            :style="
              act === 1 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 1"
          >
            <template v-if="!content.list[1].img">
              375*375像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[1].img" />
          </div>
          <div
            class="stylesFourItemTwo"
            :style="
              act === 2 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 2"
          >
            <template v-if="!content.list[2].img">
              375*375像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[2].img" />
          </div>
        </div>
      </div>

      <div class="stylesFive" v-if="content.stylesType == 5">
        <div
          class="stylesFiveItem"
          :style="
            act === 0 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 0"
        >
          <template v-if="!content.list[0].img">
            <div>宽188像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[0].img" />
        </div>
        <div
          class="stylesFiveItem"
          :style="
            act === 1 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 1"
        >
          <template v-if="!content.list[1].img">
            <div>宽188像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[1].img" />
        </div>
        <div
          class="stylesFiveItem"
          :style="
            act === 2 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 2"
        >
          <template v-if="!content.list[2].img">
            <div>宽188像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[2].img" />
        </div>
        <div
          class="stylesFiveItem"
          :style="
            act === 3 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
          "
          @click="act = 3"
        >
          <template v-if="!content.list[3].img">
            <div>宽188像素</div>
            <div>高度不限</div>
          </template>
          <img v-else class="imgItem" :src="content.list[3].img" />
        </div>
      </div>

      <div class="stylesSix" v-if="content.stylesType == 6">
        <div class="stylesSixItem">
          <div
            class="stylesSixItem_item"
            :style="
              act === 0 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 0"
          >
            <template v-if="!content.list[0].img">
              375*188像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[0].img" />
          </div>
          <div
            class="stylesSixItem_item"
            :style="
              act === 1 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 1"
          >
            <template v-if="!content.list[1].img">
              375*188像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[1].img" />
          </div>
        </div>
        <div class="stylesSixItem">
          <div
            class="stylesSixItem_item"
            :style="
              act === 2 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 2"
          >
            <template v-if="!content.list[2].img">
              375*188像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[2].img" />
          </div>
          <div
            class="stylesSixItem_item"
            :style="
              act === 3 ? { color: '#4073fa', border: '1px solid #4073fa' } : ''
            "
            @click="act = 3"
          >
            <template v-if="!content.list[3].img">
              375*188像素或同比例
            </template>
            <img v-else class="imgItem" :src="content.list[3].img" />
          </div>
        </div>
      </div>
    </div>
    <div class="listBox">
      <div class="dragBox">
        <!-- <img :src="content.list[act].img" /> -->
      </div>
      <!-- <div class="imageBox">
        <i class="el-icon-camera-solid" style="font-size: 30px"></i>
      </div> -->
      <div class="imageBox" v-if="img" @click="show = true">
        <!-- <i class="el-icon-error" style="font-size: 22px"></i> -->
        <img :src="img" />
      </div>
      <div class="imageBox" v-else @click="show = true">
        <i class="el-icon-camera-solid" style="font-size: 30px"></i>
      </div>
      <div class="rightConter">
        <div class="info-item">
          <span class="info-title">链接</span>
          <div class="input-box">
            <el-input
              class="inputItem"
              disabled
              v-model="value"
              placeholder="请选择内容"
            >
              <template slot="append">
                <el-button
                  slot="append"
                  icon="el-icon-arrow-right"
                  @click="showLink = true"
                ></el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 12px; box-sizing: border-box">
      <div class="styles-box">
        <div class="styles-item">背景颜色</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGround"
            @change="colorChange"
          ></el-color-picker
          ><span class="reset" style="margin-right: 17px" @click="colorChange()"
            >重置</span
          >
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          背景样式
          <span>{{ content.styles.borderRadius ? "圆角" : "直角" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.borderRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
            <img class="iconImg" src="@/assets/icon/icon1.png" />
          </div>
          <div
            :class="
              !content.styles.borderRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
            <img class="iconImg" src="@/assets/icon/icon2.png" />
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">背景边距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.paddingLorR"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.paddingLorR"
          ></el-input>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>
    <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>
<script>
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "Product",
  components: {
    ChangeMaterial,
    ChangeLink,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      showLink: false,
      stylesTypeE: {
        1: "样式一",
        2: "样式二",
        3: "样式三",
        4: "样式四",
        5: "样式五",
        6: "样式六",
      },
      act: 0,
      img: "",
      value: "",
      content: {
        stylesType: 1,
        list: [
          {
            img: "",
            value: "",
          },
        ],
        styles: {
          backGround: "#fff",
          borderRadius: true, //圆角
          paddingLorR: 10,
          marginTop: 0,
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
    act(newValue) {
      this.img = this.content.list[newValue].img;
      this.value = this.content.list[newValue].value;
    },
    immediate: true,
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    changwImg(val) {
      this.img = val;
      this.content.list[this.act].img = val;
      this.show = false;
    },
    changeUrl(val) {
      this.value = val;
      this.content.list[this.act].value = val;
      this.showLink = false;
    },
    stylesChange(e) {
      this.act = 0;
      this.img = "";
      this.content.stylesType = e;
      if (e == 1) {
        this.content.list = [
          {
            img: "",
            value: "",
          },
        ];
      }
      if (e == 2) {
        this.content.list = [
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
        ];
      }
      if (e == 3 || e == 4) {
        this.content.list = [
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
        ];
      }
      if (e == 5 || e == 6) {
        this.content.list = [
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
          {
            img: "",
            value: "",
          },
        ];
      }
    },
    colorChange(e) {
      if (!e) {
        this.content.styles.backGround = "#fff";
      }
    },
    textChange(e) {
      if (!e) {
        this.content.styles.textColor = "#000000";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      if (this.content.list.length == 1) {
        this.$message.error("最少需要一个");
        return;
      }
      this.content.list.splice(index, 1);
    },
    add() {
      let obj = {
        title: "",
        value: "",
      };
      if (this.content.list.length < 10) {
        this.content.list.push(obj);
      } else {
        this.$message.error("最多可添加10张图片");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  span {
    color: #999;
    margin-right: 10px;
  }
}
.dragBox {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-left: 15px;
}
.cardBox {
  border: 1px solid #eee;
  -webkit-box-shadow: 0 0 10px #eee;
  box-shadow: 0 0 10px #eee;
  padding: 18px;
  box-sizing: border-box;
  position: relative;
}
.el-icon-error {
  position: absolute;
  top: -10px;
  right: -10px;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 225px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
.tabBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}
.itemTab {
  width: 67px;
  height: 34px;
  margin-right: 15px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdee2;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}
.boxTitle {
  color: #606266;
  font-size: 14px;
  margin-top: 30px;
}
.boxTitle2 {
  color: #00000073;
  font-size: 12px;
  margin-bottom: 5px;
}
.stylesOne {
  width: 380px;
  height: 380px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
  color: #8c8c8c;
  font-size: 12px;
}
.stylesTwo {
  width: 380px;
  height: 189px;
  display: flex;
  align-items: center;
}
.stylesTwoItem {
  width: 50%;
  height: 100%;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  color: #8c8c8c;
  font-size: 12px;
}
.stylesThree {
  width: 380px;
  height: 127px;
  display: flex;
  align-items: center;
}
.stylesThreeItem {
  width: 33.333%;
  height: 100%;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  color: #8c8c8c;
  font-size: 12px;
}
.stylesFour {
  width: 380px;
  height: 189px;
  display: flex;
  align-items: center;
}
.stylesFourItem {
  width: 50%;
  height: 100%;
}
.stylesFourItemOne {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  color: #8c8c8c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.stylesFourItemTwo {
  width: 100%;
  height: 50%;
  background-color: #eeeeee;
  color: #8c8c8c;
  font-size: 12px;
  display: flex;
  border: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.stylesFive {
  width: 380px;
  height: 95px;
  display: flex;
  align-items: center;
}
.stylesFiveItem {
  width: 25%;
  height: 100%;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  color: #8c8c8c;
  font-size: 12px;
}
.stylesSix {
  width: 380px;
  height: 190px;
}
.stylesSixItem {
  width: 100%;
  height: 50%;
  display: flex;
}
.stylesSixItem_item {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  color: #8c8c8c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.imgItem {
  width: 100%;
  height: 100%;
}
</style>