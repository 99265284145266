<template>
  <div class="product-content">
    <div >
      <div>
        <p class="titleBox">
          <span>选择样式</span
          >{{
            content.styles.stylesType == "one"
              ? "单行"
              : content.styles.stylesType == "two"
              ? "多行"
              : "模块"
          }}
        </p>
        <div class="tabBox">
          <div
            class="itemTab"
            :style="
              content.styles.stylesType == 'one'
                ? 'border:1px solid #4073FA;'
                : ''
            "
            @click="content.styles.stylesType = 'one'"
          >
          <img class="iconImg" src="@/assets/icon/icon15.png"/>
          </div>
          <div
            class="itemTab"
            :style="
              content.styles.stylesType == 'two'
                ? 'border:1px solid #4073FA;'
                : ''
            "
            @click="content.styles.stylesType = 'two'"
          >
          <img class="iconImg" src="@/assets/icon/icon16.png"/>
          </div>
          <div
            class="itemTab"
            :style="
              content.styles.stylesType == 'templates'
                ? 'border:1px solid #4073FA;'
                : ''
            "
            @click="content.styles.stylesType = 'templates'"
          >
          <img class="iconImg" src="@/assets/icon/icon20.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">主题风格</div>
        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.themeColor"
            @change="colorChange"
          ></el-color-picker
          ><span class="reset" style="margin-right: 17px" @click="colorChange()"
            >重置</span
          >
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          背景样式
          <span>{{ content.styles.backgroundRadius ? "圆角" : "直角" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.backgroundRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="
              content.styles.backgroundRadius = !content.styles.backgroundRadius
            "
          >
          <img class="iconImg" src="@/assets/icon/icon1.png"/>
          </div>
          <div
            :class="
              !content.styles.backgroundRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="
              content.styles.backgroundRadius = !content.styles.backgroundRadius
            "
          >
          <img class="iconImg" src="@/assets/icon/icon2.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          内容样式
          <span>{{ content.styles.contentRadius ? "圆角" : "直角" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.contentRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="
              content.styles.contentRadius = !content.styles.contentRadius
            "
          >
          <img class="iconImg" src="@/assets/icon/icon1.png"/>
          </div>
          <div
            :class="
              !content.styles.contentRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="
              content.styles.contentRadius = !content.styles.contentRadius
            "
          >
          <img class="iconImg" src="@/assets/icon/icon2.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Presale",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "content",
      content: {
        showName:true,
        showPrice:true,
        showLabel:true,
        styles: {
          stylesType: "one", //one单行 two多行 templates模板
          themeColor: "#fff",
          backgroundRadius: true, //圆角
          contentRadius: true, //圆角
          marginTop: 10,
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    colorChange(e) {
      if (!e) {
        this.content.styles.themeColor = "#fff";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      this.content.list.splice(index, 1);
    },
    add() {
      let obj = {
        title: "",
        value: "",
      };
      this.content.list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  span {
    color: #999;
    margin-right: 10px;
  }
}
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.tabBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.itemTab {
  width: 67px;
  height: 34px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdee2;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
.selectBox {
  display: flex;
  margin-top: 25px;
  align-items: center;
  span {
    color: #606266;
    font-size: 14px;
    margin-right: 15px;
  }
}
.imgBox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.imgItem {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-icon-error {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-icon-plus {
    font-size: 23px;
    color: #d8d8d8;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>