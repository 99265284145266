<template>
  <div class="product-content">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabBox">
      <el-tab-pane label="内容设置" name="content"></el-tab-pane>
      <el-tab-pane label="样式设置" name="styles"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'content'">
      <div class="headTips">最多可添加10张图片，建议宽度750*345px,鼠标拖拽左侧圆点可调整图片排序</div>
      <Draggable
        v-model="content.list"
        animation="300"
        draggable=".listBox" 
        handle=".dragBox"
      >
        <div class="listBox" v-for="(item, index) in content.list" :key="index">
          <div class="dragBox">
            <img src="@/assets/images/dian.png" />
          </div>

          <div class="imageBox" v-if="item.img">
            <i
              class="el-icon-error"
              @click.stop="del(index)"
              style="font-size: 22px"
            ></i>
            <img :src="item.img" @click="showDio(index)" /> 
          </div>

          <div class="imageBox" v-else @click="showDio(index)">
            <i
              class="el-icon-error"
              @click.stop="del(index)"
              style="font-size: 22px"
            ></i>
            <i class="el-icon-camera-solid" style="font-size: 30px"></i>
          </div>
          <div class="rightConter">
            <div class="info-item">
              <span class="info-title">标题</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  v-model="item.title"
                  placeholder="选填，不超过4个字"
                  maxlength="4"
                ></el-input>
              </div>
            </div>
            <div class="info-item" style="margin-top: 10px">
              <span class="info-title">链接</span>
              <div class="input-box">
                <el-input
                  class="inputItem"
                  v-model="item.value"
                  disabled
                  placeholder="请选择内容"
                >
                  <template slot="append">
                    <el-button
                      slot="append"
                      @click="linkDio(index)"
                      icon="el-icon-arrow-right"
                    ></el-button>
                  </template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
      <el-button v-if='content.list.length<10' plain style="width: 100%; height: 40px" @click="add"
        >添加板块</el-button
      >
    </div>

    <div v-if="activeName == 'styles'">
      <div class="styles-box">
        <div class="styles-item">
          轮播图样式
          <span>{{ content.styles.borderRadius ? "圆角" : "直角" }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.borderRadius
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
          <img class="iconImg" src="@/assets/icon/icon1.png"/>
          </div>
          <div
            :class="
              !content.styles.borderRadius
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.borderRadius = !content.styles.borderRadius"
          >
          <img class="iconImg" src="@/assets/icon/icon2.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          指示器样式
          <span>{{ indicatorE[content.styles.indicator] }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.indicator == 'circular'
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.indicator = 'circular'"
          >
          <img class="iconImg" src="@/assets/icon/icon3.png"/>
          </div>
          <div
            :class="
              content.styles.indicator == 'line'
                ? 'setting-itemAct'
                : 'setting-item'
            "
            @click="content.styles.indicator = 'line'"
          >
          <img class="iconImg" src="@/assets/icon/icon3.png"/>
          </div>
          <div
            :class="
              content.styles.indicator == 'none'
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.indicator = 'none'"
          >
          <img class="iconImg" src="@/assets/icon/icon4.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">
          指示器位置
          <span>{{
            indicatorPositionE[content.styles.indicatorPosition]
          }}</span>
        </div>

        <div class="setting-box">
          <div
            :class="
              content.styles.indicatorPosition == 'left'
                ? 'setting-itemAct borderL'
                : 'setting-item borderL'
            "
            @click="content.styles.indicatorPosition = 'left'"
          >
          <img class="iconImg" src="@/assets/icon/icon5.png"/>
          </div>
          <div
            :class="
              content.styles.indicatorPosition == 'center'
                ? 'setting-itemAct'
                : 'setting-item'
            "
            @click="content.styles.indicatorPosition = 'center'"
          >
          <img class="iconImg" src="@/assets/icon/icon6.png"/>
          </div>
          <div
            :class="
              content.styles.indicatorPosition == 'right'
                ? 'setting-itemAct borderR'
                : 'setting-item borderR'
            "
            @click="content.styles.indicatorPosition = 'right'"
          >
          <img class="iconImg" src="@/assets/icon/icon7.png"/>
          </div>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">否显示背景色</div>

        <div class="setting-box">
          <el-switch
            v-model="content.styles.isShowBackGround"
            active-color="#4073fa"
          >
          </el-switch>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">背景颜色(渐变)</div>

        <div class="setting-box">
          <el-color-picker
            v-model="content.styles.backGroundStart"
            @change="startChange"
          ></el-color-picker
          ><span class="reset" style="margin-right: 17px">重置</span>
          <el-color-picker
            v-model="content.styles.backGroundEnd"
            @change="endChange"
          ></el-color-picker
          ><span class="reset">重置</span>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">左右边距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.paddingLorR"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.paddingLorR"
          ></el-input>
        </div>
      </div>

      <div class="styles-box">
        <div class="styles-item">页面间距</div>

        <div class="setting-box">
          <el-slider
            style="width: 120px"
            v-model="content.styles.marginTop"
          ></el-slider>
          <el-input
            class="sliderBox"
            v-model="content.styles.marginTop"
          ></el-input>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>
    <el-dialog
      title="选择链接"
      :append-to-body="true"
      :visible.sync="showLink"
      width="80%"
    >
      <ChangeLink @changeUrl="changeUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Upload } from "element-ui";
import Draggable from "vuedraggable";
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
import ChangeLink from "@/view/decorate/page/components/changeLink";
export default {
  name: "Product",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Draggable,
    ChangeMaterial,
    ChangeLink,
  },
  data() {
    return {
      clickIndex: null,
      show: false,
      showLink: false,
      activeName: "content",
      value: "",
      value1: "",
      color1: "",
      indicatorE: {
        circular: "圆形",
        line: "线",
        none: "无",
      },
      indicatorPositionE: {
        left: "居左",
        center: "居中",
        right: "居右",
      },
      content: {
        list: [
          {
            img: "",
            title: "",
            value: "",
          },
        ],
        styles: {
          borderRadius: true, //圆角
          indicator: "circular", //circular圆 line线 none无
          indicatorPosition: "left", //left居左 right居右 center居中
          isShowBackGround: true,
          backGroundStart: "#fff",
          backGroundEnd: "#fff",
          paddingLorR: 10,
          marginTop: 0,
        },
      },
    };
  },
  watch: {
    content: {
      handler: function () {
        this.uploadVuex();
      },
      deep: true, // 设置为true来监听对象内部属性的变化
    },
  },
  created() {
    if (this.data.content) {
      this.content = this.data.content;
    }
    this.uploadVuex();
  },
  methods: {
    showDio(index) {
      this.clickIndex = index;
      this.show = true;
    },
    linkDio(index) {
      this.clickIndex = index; 
      this.showLink = true;
    },
    changwImg(val) {
      this.content.list[this.clickIndex].img = val;
      this.show = false;
    },
    changeUrl(val) {
      this.content.list[this.clickIndex].value = val;
      this.showLink = false;
    },
    startChange(e) {
      if (!e) {
        this.content.styles.backGroundStart = "#fff";
      }
    },
    endChange(e) {
      if (!e) {
        this.content.styles.backGroundEnd = "#fff";
      }
    },
    uploadVuex() {
      let arr = this.$store.state.pagesCustom.pageInfo;
      arr.map((item) => {
        if (item.num == this.data.num) {
          item.content = this.content;
        }
      });
      this.$store.commit("setPage", []);
      this.$store.commit("setPage", arr);
    },
    handleClick() {},
    del(index) {
      if(this.content.list.length==1){
        this.$message.error('至少保留一个')
        return
      }
      this.content.list.splice(index, 1);
    },
    add() {
      let obj = {
        img: "",
        title: "",
        value: "",
      };
      this.content.list.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.dragBox {
  width: 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
  img {
    height: 16px;
    cursor: pointer;
  }
}
.product-content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.tabBox {
  width: 100%;
}
.headTips {
  padding: 13px 0;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.listBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.imageBox {
  width: 70px;
  height: 70px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img { 
    width: 100%;
    height: 100%;
  }
}
.el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid {
  color: #c0c4cc;
  width: 30px;
  height: 30px;
}
.rightConter {
  display: flex;
  flex-direction: column;
}
.info-item {
  display: flex;
  align-items: center;
}
.info-title {
  font-size: 13px;
  color: #606266;
  width: 40px;
}
.input-box {
  width: 205px;
  height: 32px !important;
}
.inputItem {
  width: 205px !important;
  height: 32px !important;
}
.inputItem > input {
  height: 32px !important;
}
.styles-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.styles-item {
  display: flex;
  font-size: 14px;
  color: #999;
  span {
    margin-left: 10px;
    color: #333;
  }
}
.setting-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-item {
  width: 51px;
  height: 32px;
  border: 1px solid #dcdee2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting-itemAct {
  width: 51px;
  height: 32px;
  border: 1px solid #4073fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderL {
  border-radius: 4px 0 0 4px;
}
.borderR {
  border-radius: 0 4px 4px 0;
}
.reset {
  display: block;
  color: #999;
  font-size: 13px;
  margin-left: 5px;
}
.sliderBox {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}
.sliderBox > input {
  height: 32px !important;
  margin-left: 12px;
  text-align: center;
}
</style>