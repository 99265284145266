var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-content"},[_c('el-tabs',{staticClass:"tabBox",on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"内容设置","name":"content"}}),_c('el-tab-pane',{attrs:{"label":"样式设置","name":"styles"}})],1),(_vm.activeName == 'content')?_c('div',[_c('div',{staticClass:"Tips",staticStyle:{"margin-bottom":"20px"}},[_vm._v("设置名称")]),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.content.title),callback:function ($$v) {_vm.$set(_vm.content, "title", $$v)},expression:"content.title"}})],1):_vm._e(),(_vm.activeName == 'styles')?_c('div',[_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v("背景颜色")]),_c('div',{staticClass:"setting-box"},[_c('el-color-picker',{on:{"change":_vm.colorChange},model:{value:(_vm.content.styles.backGround),callback:function ($$v) {_vm.$set(_vm.content.styles, "backGround", $$v)},expression:"content.styles.backGround"}}),_c('span',{staticClass:"reset",staticStyle:{"margin-right":"17px"},on:{"click":function($event){return _vm.colorChange()}}},[_vm._v("重置")])],1)]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v(" 边框样式 "),_c('span',[_vm._v(_vm._s(_vm.content.styles.borderRadius ? "圆角" : "直角"))])]),_c('div',{staticClass:"setting-box"},[_c('div',{class:_vm.content.styles.borderRadius
              ? 'setting-itemAct borderL'
              : 'setting-item borderL',on:{"click":function($event){_vm.content.styles.borderRadius = !_vm.content.styles.borderRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon1.png")}})]),_c('div',{class:!_vm.content.styles.borderRadius
              ? 'setting-itemAct borderR'
              : 'setting-item borderR',on:{"click":function($event){_vm.content.styles.borderRadius = !_vm.content.styles.borderRadius}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon2.png")}})])])]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v(" 文本位置 "),_c('span',[_vm._v(_vm._s(_vm.textPositionE[_vm.content.styles.textPosition]))])]),_c('div',{staticClass:"setting-box"},[_c('div',{class:_vm.content.styles.textPosition == 'left' 
              ? 'setting-itemAct borderL'
              : 'setting-item borderL',on:{"click":function($event){_vm.content.styles.textPosition = 'left'}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon5.png")}})]),_c('div',{class:_vm.content.styles.textPosition == 'center'
              ? 'setting-itemAct borderR'
              : 'setting-item borderR',on:{"click":function($event){_vm.content.styles.textPosition = 'center'}}},[_c('img',{staticClass:"iconImg",attrs:{"src":require("@/assets/icon/icon6.png")}})])])]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v("背景边距")]),_c('div',{staticClass:"setting-box"},[_c('el-slider',{staticStyle:{"width":"120px"},model:{value:(_vm.content.styles.paddingL),callback:function ($$v) {_vm.$set(_vm.content.styles, "paddingL", $$v)},expression:"content.styles.paddingL"}}),_c('el-input',{staticClass:"sliderBox",model:{value:(_vm.content.styles.paddingL),callback:function ($$v) {_vm.$set(_vm.content.styles, "paddingL", $$v)},expression:"content.styles.paddingL"}})],1)]),_c('div',{staticClass:"styles-box"},[_c('div',{staticClass:"styles-item"},[_vm._v("页面间距")]),_c('div',{staticClass:"setting-box"},[_c('el-slider',{staticStyle:{"width":"120px"},model:{value:(_vm.content.styles.marginTop),callback:function ($$v) {_vm.$set(_vm.content.styles, "marginTop", $$v)},expression:"content.styles.marginTop"}}),_c('el-input',{staticClass:"sliderBox",model:{value:(_vm.content.styles.marginTop),callback:function ($$v) {_vm.$set(_vm.content.styles, "marginTop", $$v)},expression:"content.styles.marginTop"}})],1)])]):_vm._e(),_c('el-dialog',{attrs:{"title":"选择图片","append-to-body":true,"visible":_vm.show,"width":"80%"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('ChangeMaterial',{on:{"changwImg":_vm.changwImg}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }